<ion-header no-border *ngIf="!isModal">
  <header></header>
</ion-header>

<ion-header *ngIf="isModal">
  <ion-toolbar color="green">
    <ion-title>
        Registreren
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <ion-grid>
    <ion-row class="ion-justify-content-center">
      <ion-col size-lg="10">

        <div class="ion-padding">
          <ion-img class="center-image" width="100" height="100" src="../../assets/img/IconBoekenbalie.png"></ion-img>
        </div>

        <ion-card class="bordered">
          <ion-card-header>
            <h3>Registreren</h3>
          </ion-card-header>

          <ion-card-content>
            <p class="ion-margin-bottom">Om je boeken aan ons te verkopen hebben we de volgende gegevens van je nodig:</p>

            <form [formGroup]="form" (ngSubmit)="submit()" data-cy="signup-form">
              <ion-list>
                <ion-item>
                  <ion-label position="stacked" translate [class.required]="form.controls['email'] | isRequiredField">page.signup.fields.email</ion-label>
                  <ion-input formControlName="email" type="email" name="email" data-cy="email-input-field" (change)="form.controls['email'].setValue($event.target.value)"></ion-input>
                </ion-item>
                <error [formControl]="form.controls.email" [showIf]="submitted" [showByTouched]="true" ngDefaultControl data-cy="email-error"></error>
                <ion-item>
                  <ion-label position="stacked" translate [class.required]="form.controls['new_password'] | isRequiredField">page.signup.fields.enter_password</ion-label>
                  <ion-input #newPassword formControlName="new_password" type="password" name="password" data-cy="new-password-input-field" (change)="form.controls['new_password'].setValue($event.target.value)"></ion-input>
                  <ion-button class="type-toggle" slot="end" color="salmon" size="small" fill="clear" password-mask-toggle showLabel="Toon" hideLabel="Verberg" [targetInput]="newPassword"></ion-button>
                </ion-item>
                <error [formControl]="form.controls.new_password" [showIf]="submitted" [showByTouched]="true"
                  ngDefaultControl data-cy="new-password-error"></error>
                <ion-item>
                  <ion-label position="stacked" translate [class.required]="form.controls['confirmPassword'] | isRequiredField">page.signup.fields.confirmPassword</ion-label>
                  <ion-input #confirmPassword formControlName="confirmPassword" type="password" name="confirmPassword" data-cy="confirm-password-input-field" (change)="form.controls['confirmPassword'].setValue($event.target.value)"></ion-input>
                  <ion-button class="type-toggle" slot="end" color="salmon" size="small" fill="clear" password-mask-toggle showLabel="Toon" hideLabel="Verberg" [targetInput]="confirmPassword"></ion-button>
                </ion-item>
                <error [formControl]="form.controls.confirmPassword" [showIf]="submitted" [showByTouched]="true"
                  ngDefaultControl data-cy="confirm-password-error"></error>
                <error [form]="form" [showIf]="submitted" [showByTouched]="form.controls.new_password.touched && form.controls.confirmPassword.touched"
                  [hideIf]="!form.controls.new_password.valid || !form.controls.confirmPassword.valid"
                  data-cy="confirm-password-mismatch-error"></error>
                <ion-item>
                  <ion-label position="stacked" translate [class.required]="form.controls['type_explanation'] | isRequiredField">page.signup.fields.type_explanation</ion-label>
                  <ion-select [formControl]="form.controls.type_explanation" okText="Ok" cancelText="Annuleren" class="type-select">
                    <ion-select-option value="A">Als particulier wil ik mijn eigen boekenkast opruimen</ion-select-option>
                    <ion-select-option value="B">Als student wil ik mijn studieboeken verkopen</ion-select-option>
                    <ion-select-option value="C">Als particulier wil ik van boeken af die ik verkregen heb van anderen</ion-select-option>
                    <ion-select-option value="D">Als particulier zamel ik boeken in met als doel ze te verhandelen</ion-select-option>
                    <ion-select-option value="E">Namens een kringloopwinkel scan/verkoop ik boeken</ion-select-option>
                    <ion-select-option value="F">Namens een NGO / goed doel verkoop ik boeken</ion-select-option>
                    <ion-select-option value="G">Namens een zakelijke partij verkoop ik boeken</ion-select-option>
                    <ion-select-option value="H">Namens een ander type organisatie verkoop ik boeken</ion-select-option>
                  </ion-select>
                </ion-item>
                <ion-item class="inline-checkbox ion-margin-bottom" lines="none">
                  <ion-label class="ion-text-wrap">Je mag contact met me opnemen over mijn boekenlijst of een nieuwsbrief sturen</ion-label>
                  <ion-checkbox color="salmon" slot="start" [formControl]="form.controls['newsletter']" data-cy="newsletter-input-field"></ion-checkbox>
                </ion-item>
                <error [formControl]="form.controls['newsletter']" [showIf]="submitted" ngDefaultControl></error>
                <div padding-top>
                  <ion-button color="salmon" expand="block" type="submit" data-cy="signup-button" translate>page.signup.submit</ion-button>
                </div>
              </ion-list>
            </form>

          </ion-card-content>
        </ion-card>

        <div>
          <ion-button (click)="goToLogin()" fill="clear" color="salmon" expand="block">Terug naar inloggen</ion-button>
        </div>

      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
