import { StateConditionsPage } from './../../pages/information/state-conditions/state-conditions.component';
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { StorageService } from '../../providers/utils/storage.service';

@Injectable()
export class StateConditionsModalProvider {
  public modal: HTMLIonModalElement;

  constructor(
    private modalCtrl: ModalController,
    private storageService: StorageService
  ) { }

  async openModal(modalHeader, checkoutModal?) {
    this.modal = await this.modalCtrl.create({
      component: StateConditionsPage,
      componentProps: {
        showModalHeader: modalHeader,
        isCheckoutModal: checkoutModal
      },
    });

    if (checkoutModal) {
      this.modal.backdropDismiss = false;
    }

    this.modal.onDidDismiss().then(() => {
      this.storageService.set('checkoutModalShown', true);
    });

    this.modal.present();
  }
}
