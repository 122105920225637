import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ScannerStartPage } from './scanner-start';
import { ScannerResultPage } from './../scanner-result/scanner-result';
import { ScannerResultDonablePage } from './../scanner-result-donable/scanner-result-donable';
import { ScannerModalPage } from './../scanner-modal/scanner-modal';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { ErrorModule } from './../../components/forms/error/error.module';
import { HeaderModule } from '../../components/header/header.module';
import { CommonModule } from '@angular/common';
import { ZXingScannerModule } from '@zxing/ngx-scanner';

const routes: Routes = [
  { path: '', component: ScannerStartPage },
];

@NgModule({
  declarations: [
    ScannerStartPage,
    ScannerResultPage,
    ScannerResultDonablePage,
    ScannerModalPage
  ],
  providers: [
    OpenNativeSettings
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    ErrorModule,
    ZXingScannerModule,
    HeaderModule,
    RouterModule.forChild(routes),
  ]
})
export class ScannerStartPageModule {}
