import { Injectable } from '@angular/core';
import { UrlTree } from '@angular/router';
import { NavController } from '@ionic/angular';

type Url = string | any[] | UrlTree;

@Injectable()
export class NavService {

  params: any;

  constructor(
    public navCtrl: NavController,
  ) {
  }

  getParam(key: string): any {
    return this.params[key];
  }

  navigateForward(url: Url, params: any = {}, options?) {
    this.params = params;

    return this.navCtrl.navigateForward(url, options);
  }

  navigateBack(url: Url, options?) {
    return this.navCtrl.navigateBack(url, options);
  }

  navigateRoot(url: Url, options?) {
    return this.navCtrl.navigateRoot(url, options);
  }
}
