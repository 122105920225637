import { AppConfig } from './constants/app-config';
import { Book } from './../interfaces/books';
import { BookModel } from './book';
import { Model } from '../generics/model';

export class BookListModel extends Model {
  constructor(fields: any = {}) {
    super();

    for (const f of Object.keys(fields)) {
      this[f] = fields[f];
    }
  }

  list_info: {
    list_id: string;
    count: number;
    amount: number;
    donate_percentage: number;
    total_amount: number;
    promo_amount: number;
    bonus_amount: number;
    total_user_amount: number;
    total_donate_amount: number;
  };

  list: BookModel[] = [];

  get length() {
    return this.list && this.list.length > 0 ? this.list.length : 0;
  }

  get totalWeight() {
    const weight = this.list && this.list.length > 0 ? this.list.reduce((a, b) => +a + +b.weight, 0) : 0;
    return Number(weight.toFixed(2));
  }

  get totalWeightKg() {
    const weight = this.list && this.list.length > 0 ? this.list.reduce((a, b) => +a + +b.weight / 1000, 0) : 0;
    return Number(weight.toFixed(1));
  }

  get totalPriceEuro() {
    return this.list && this.list.length > 0 ? this.list.reduce((a, b) => +a + +b.price / 100, 0) : 0;
  }

  get totalPrice() {
    return this.list && this.list.length > 0 ? this.list.reduce((a, b) => +a + +b.price, 0) : 0;
  }

  get minAmountReached() {
    return (this.totalPrice > 0 && this.totalPrice > AppConfig.bookList.minAmount * 100 - 1);
  }

  get maxAmountReached() {
    return this.additionExceedsMaxAmount(0);
  }

  get minBooksReached() {
    return this.list && this.list.length > 9;
  }

  get canGoToCheckout() {
    return (this.minAmountReached && !this.maxAmountReached) || this.minBooksReached;
  }

  additionExceedsMaxAmount(price: number): boolean {
    return this.totalPrice + price > AppConfig.bookList.maxAmount * 100;
  }

  countBookExistInList(toCheckBook: Book): number {
    if (!this.list) {
      return 0;
    }
    const books = this.list.filter((book) => book.isbn === toCheckBook.isbn);
    return books.length;
  }
}
