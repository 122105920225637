export const ENV = {
  production: true,
  isDebugMode: false,
  allowReviewPromo: false,
  firebase: {
    apiKey: 'AIzaSyBmHb-uBGcoWbeMOAde1YbGFtMHI2W8ZjM',
    authDomain: 'warm-skill-122509.firebaseapp.com',
    databaseURL: 'https://warm-skill-122509.firebaseio.com',
    projectId: 'warm-skill-122509',
    storageBucket: 'warm-skill-122509.appspot.com',
    messagingSenderId: '940712707481',
    appId: '1:940712707481:web:9b4e9856bf40b98ee2ff08',
    measurementId: 'G-ERZTC6L69E',
  },
  instabug: {
    apiKey: '11d0c9732fad4de1c9d7374215b141dc',
  }
};
