<ion-header no-border *ngIf="!isModal">
  <header></header>
</ion-header>

<ion-header *ngIf="isModal">
  <ion-toolbar color="green">
    <ion-buttons slot="start" *ngIf="showBackButton">
      <ion-button (click)="closeModal()">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
        Inloggen
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <ion-grid>
    <ion-row class="ion-justify-content-center">
      <ion-col size-lg="10">

        <div class="ion-padding">
          <ion-img class="center-image" width="100" height="100" src="../../assets/img/IconBoekenbalie.png"></ion-img>
        </div>

        <ion-card>
          <ion-card-content>
            <h2 class="ion-text-center ion-padding">Heb je al een account?</h2>
            <form [formGroup]="accountForm" (keydown)="doLogin($event)" data-cy="login-form">
              <ion-list>
                <ion-item>
                  <ion-label position="stacked" [class.required]="accountForm.controls['email'] | isRequiredField">E-mailadres</ion-label>
                  <ion-input formControlName="email" type="email" name="email" autocomplete="username"
                    data-cy="email-input-field" (change)="accountForm.controls['email'].setValue($event.target.value)"></ion-input>
                </ion-item>
                <error [formControl]="accountForm.controls['email']" [showIf]="submitted" [showByTouched]="false"
                  data-cy="email-error" ngDefaultControl></error>
                <ion-item>
                  <ion-label position="stacked" [class.required]="accountForm.controls['password'] | isRequiredField">Wachtwoord</ion-label>
                  <ion-input #passwordInput formControlName="password" type="password" name="password" autocomplete="current-password"
                    data-cy="password-input-field" (change)="accountForm.controls['password'].setValue($event.target.value)"></ion-input>
                  <ion-button class="type-toggle" slot="end" color="salmon" size="small" fill="clear" password-mask-toggle showLabel="Toon" hideLabel="Verberg" [targetInput]="passwordInput"></ion-button>
                </ion-item>
                <error [formControl]="accountForm.controls['password']" [showIf]="submitted" [showByTouched]="false"
                  data-cy="password-error" ngDefaultControl></error>
                <ion-text>
                  <p color="danger" *ngIf="accountFormErrorMessage" padding-left>
                    {{accountFormErrorMessage}}
                  </p>
                </ion-text>
                <div class="ion-padding-top">
                  <ion-button data-cy="login-button" color="salmon" expand="block"
                    (click)="doLogin()">{{ 'LOGIN_BUTTON' | translate }}</ion-button>
                </div>
              </ion-list>
            </form>
            <div>
              <ion-button data-cy="forgot-password-button" expand="block" color="green" fill="clear"
                (click)="forgotPassword()">Wachtwoord vergeten?</ion-button>
            </div>
          </ion-card-content>
        </ion-card>

        <ion-card>
          <ion-card-content>
            <h2 class="ion-text-center ion-padding">Waarom een account?</h2>
            <div class="create-account-info">
              <p>
                <ion-icon name="checkmark-circle" color="salmon"></ion-icon> Om boeken te kunnen verkopen
              </p>
              <p>
                <ion-icon name="checkmark-circle" color="salmon"></ion-icon> Om de status te bekijken van je zending
              </p>
              <p>
                <ion-icon name="checkmark-circle" color="salmon"></ion-icon> Om te zien of wij het juiste e-mailadres hebben
              </p>
            </div>
            <div class="ion-margin-top">
              <ion-button data-cy="signup-button-nav" (click)="openSignup()" color="salmon" expand="block" >{{ 'SIGNUP' | translate }}</ion-button>
            </div>
          </ion-card-content>
        </ion-card>

        <ion-card  *ngIf="!isModal">
          <ion-card-content>
            <h2 class="ion-text-center ion-padding">Al mijn gegevens wissen uit de app</h2>
            <p class="ion-text-center">
              Als je problemen hebt met de app klik dan hier om opnieuw te beginnen.
            </p>
            <div class="ion-margin-top">
              <ion-button (click)="doLogout()" color="salmon" expand="block">Opnieuw beginnen</ion-button>
            </div>
          </ion-card-content>
        </ion-card>

      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
