import { Component } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';
import { NavService } from './../../providers/utils/nav.service';

@Component({
  selector: 'page-offline',
  templateUrl: 'offline.html',
  styleUrls: ['offline.scss'],
})
export class OfflinePage {

  constructor(
    private modalCtrl: ModalController,
    public navService: NavService,
    public platform: Platform,
  ) {
    document.addEventListener('backbutton', this.backButtonListener, false);
  }

  ionViewWillLeave() {
    document.removeEventListener('backbutton', this.backButtonListener, false);
  }

  private readonly backButtonListener = (event) => {
    event.preventDefault();
    event.stopPropagation();
    return false;
  };

  dismissModal() {
    this.modalCtrl.dismiss();
  }

}
