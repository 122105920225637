/*
book/:book_id
*/
export class Book {
  interested: boolean;
  book_id: string;
  title: string;
  subtitle: string;
  reason: string;
  authors: string;
  isbn: string;
}

/*
book/:book_id/price
can be async or take longer
book/:book_id to get direct info about a book
*/
export class BookPrice {
  interested: boolean;
  book_id: string;
  // cents
  price: number;
  weight: number;
  isbn: string;
}
