<ng-container *ngIf="!barcodeScanner.scanActive">
  <ion-header no-border>
    <header></header>
  </ion-header>

  <ion-content color="light">
    <ion-grid>
      <ion-row class="ion-justify-content-center">
        <ion-col size-lg="10">

          <ion-card>
            <ion-card-content>
              <ion-card-title>
                <ion-text>
                  <h2 class="ion-text-center">
                    Zoeken op streepjescode
                  </h2>
                </ion-text>
              </ion-card-title>
              <p class="ion-text-center">Voeg een boek toe door de streepjescode op het boek te scannen, of door het ISBN nummer te typen.</p>
              <div class="ion-text-center ion-padding-top">
                <ion-button (click)="scanBarcode()" color="salmon" class="button-padded">Scan streepjescode</ion-button>
              </div>
            </ion-card-content>
          </ion-card>

          <ion-card>
            <ion-card-content>
              <ion-card-title>
                <ion-text>
                  <h2 class="ion-text-center" color="green">
                    Zoeken op ISBN nummer
                  </h2>
                </ion-text>
              </ion-card-title>
              <form [formGroup]="form" (ngSubmit)="searchISBN()" data-cy="isbn-form">
                <ion-list color="light">
                  <ion-item>
                    <ion-label></ion-label>
                    <ion-input #isbnInput formControlName="isbn" type="text" pattern="\d*" inputmode="numeric" placeholder="Typ hier een ISBN nummer" data-cy="isbn-input"></ion-input>
                  </ion-item>
                  <ion-item lines="none" class="numeric-check">
                    <ion-checkbox formControlName="suffix" color="salmon"></ion-checkbox>
                    <ion-label color="green">Mijn ISBN nummer eindigt op X</ion-label>
                  </ion-item>
                  <error [form]="form" [formControl]="form.controls['isbn']" [showIf]="submitted" [showByTouched]="false" ngDefaultControl></error>
                  <div class="ion-text-center ion-padding-top">
                    <ion-button data-cy="isbn-submit" color="salmon" class="button-padded" type="submit">Zoeken</ion-button>
                  </div>
                </ion-list>
              </form>
            </ion-card-content>
          </ion-card>

          <ion-card *ngIf="bookList && bookList.length > 0">
            <ion-card-content>
              <ion-card-title>
                <ion-text>
                  <h2 class="ion-text-center">
                    Huidige lijst bekijken
                  </h2>
                </ion-text>
              </ion-card-title>
              <div class="ion-text-center">
                <ion-button (click)="goToCheckoutList()" color="salmon" class="button-padded">Lijst bekijken</ion-button>
              </div>
            </ion-card-content>
          </ion-card>

          <ion-button (click)="openConditionsModal()" expand="block" color="green" fill="clear">Welke boeken zijn bruikbaar?</ion-button>

        </ion-col>
      </ion-row>
    </ion-grid>
  </ion-content>

  <ion-footer>
    <ion-button (click)="openWithInAppBrowser('https://jambb3pclhc.typeform.com/to/ydFKdDOC')" expand="block" color="green" fill="clear">Geef feedback op deze app</ion-button>
  </ion-footer>
</ng-container>

<ng-container *ngIf="barcodeScanner.scanActive">
  <div class="scan-box"></div>
  <ion-button (click)="dismissBarcodeScanner()" expand="block" color="salmon" class="cancel-button">Annuleren</ion-button>
</ng-container>
