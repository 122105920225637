import { BarcodeScannerProvider } from './shared/barcode-scanner/barcode-scanner';
import { ErrorUtil } from './../providers/utils/error-util';
import { PlatformService } from './../providers/utils/platform.service';
import { ScannerResultPageModule } from './scanner-result/scanner-result.module';
import { ScannerResultDonablePageModule } from './scanner-result-donable/scanner-result-donable.module';
import { ScannerStartPageModule } from './scanner-start/scanner-start.module';
import { ScannerModalPageModule } from './scanner-modal/scanner-modal.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ScannerServiceProvider } from './shared/scanner.service';

@NgModule({
  imports: [
    CommonModule,
    ScannerResultPageModule,
    ScannerResultDonablePageModule,
    ScannerStartPageModule,
    ScannerModalPageModule,
  ],
  declarations: [],
  providers: [
    ScannerServiceProvider,
    PlatformService,
    ErrorUtil,
    BarcodeScannerProvider,
  ],
})
export class ScannerModule {}
