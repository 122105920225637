import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable()
export class BackButtonUtil {
  constructor(
    private platform: Platform,
  ) { }

  public fixScannerBackButton(): void {
    this.platform.pause.subscribe(() => {
      document.addEventListener('backbutton', this.listener, false);
    });

    this.platform.resume.subscribe(() => {
      setTimeout(() => document.removeEventListener('backbutton', this.listener, false), 250);
    });
  }

  private readonly listener = (event) => {
    event.preventDefault();
    event.stopPropagation();
    return false;
  };
}
