import { ProfileProvider } from './../../providers/profile/profile.service';
import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import * as Sentry from '@sentry/capacitor';

export class AccountForm {
  email: string;
  password: string;
}
import { LoginData } from './../../interfaces/users';
import { AlertsProvider } from './../../providers/alerts/alerts';
import { ToastProvider } from '../../providers/toast/toast';
import { ToastMessages } from '../../models/constants/toast-messages';
import { NavService } from './../../providers/utils/nav.service';

@Component({
  selector: 'page-login',
  templateUrl: 'login.html',
  styleUrls: ['login.scss']
})

export class LoginPage {

  @Input() isModal?: boolean;
  @Input() showBackButton?: boolean = true;

  accountFormData = {
    email: '',
    password: '',
  } as AccountForm;
  accountForm: FormGroup;
  submitted = false;

  accountFormErrorMessage: string;

  constructor(
    public navService: NavService,
    public toastCtrl: ToastController,
    public translateService: TranslateService,
    public formBuilder: FormBuilder,
    public profileProvider: ProfileProvider,
    public toastProvider: ToastProvider,
    public alertsProvider: AlertsProvider,
    private modalCtrl: ModalController,
  ) {
    this.setUpForm();
  }

  setUpForm() {
    this.accountForm = this.formBuilder.group({
      email: [ this.accountFormData.email, [Validators.required, Validators.email, Validators.compose([Validators.maxLength(150)])]],
      password: [ this.accountFormData.password, [Validators.required, Validators.compose([Validators.maxLength(150)])]],
    });
  }

  checkFormIsValid() {
    return this.accountForm.valid;
  }

  // Attempt to login in through our User service
  async doLogin(event?) {
    if (event && event.keyCode !== 13) {
      return;
    }

    this.submitted = true;

    if (!this.checkFormIsValid()) {
      this.toastProvider.showToast(ToastMessages.formInvalid.text, ToastMessages.formInvalid.duration, ToastMessages.formInvalid.position);
      return;
    }

    const loginData: LoginData = {
      userdata: {
        email: this.accountForm.value.email,
        password: this.accountForm.value.password,
      },
    };

    this.profileProvider.login(loginData).then(async () => {
      if (this.isModal) {
        this.closeModal();
      } else {
        this.navService.navigateForward('checkout-list');
      }

      return (await this.toastCtrl.create({
        message: 'Inlog succesvol',
        duration: 3000,
        position: 'bottom',
        color: 'dark',
      })).present();
    }).catch(async (e) => {
      let message;

      if (e.status === 401 || e.status === 400) {
        if (e.error.status[0] === 'locked_out') {
          message = this.translateService.instant('errors.locked_out');
        } else {
          message = this.translateService.instant('errors.login_failed');
        }
      } else {
        message = this.translateService.instant('errors.server_error');
      }

      Sentry.captureMessage(`Login ${e.status}: ${message}`);

      return (await this.toastCtrl.create({
        message,
        duration: 6000,
        position: 'bottom',
        color: 'dark',
      })).present();
    });
  }

  doLogout() {
    this.profileProvider.logout();
  }

  openSignup() {
    if (this.isModal) {
      this.modalCtrl.dismiss({signupClicked: true});
    } else {
      this.navService.navigateForward('signup');
    }
  }

  forgotPassword() {
    if (this.isModal) {
      this.modalCtrl.dismiss({passwordResetClicked: true});
    } else {
      this.navService.navigateForward('password-reset');
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
