import { CheckoutListStore } from './checkout-list-store';
import { BookListSubmitSuccess, LoggedOutEvent } from './../../models/constants/events';
import { Injectable } from '@angular/core';
import { EventsService } from './../../providers/utils/events-service';

@Injectable()
export class CheckoutListEventsProvider {

  constructor(
    public eventService: EventsService,
    private checkoutListStore: CheckoutListStore,
  ) {
  }

  init() {
    this.eventService.refreshData.subscribe(eventName => {
      if (eventName === BookListSubmitSuccess) {
        this.checkoutListStore.setOpenList();
      } else if (eventName === LoggedOutEvent) {
        this.checkoutListStore.resetListToEmpty();
      }
    });
  }

}
