import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { Platform } from '@ionic/angular';

@Injectable()
export class StorageService {

  constructor(public storage: Storage, public platform: Platform) {
    this.init();
  }

  async init() {
    this.storage = await this.storage.create();
  }

  public async get(key: string): Promise<any> {
    await this.platform.ready();
    return this.storage.get(key);
  }

  public async set(key: string, value: any): Promise<{}> {
    await this.platform.ready();
    return this.storage.set(key, value);
  }

  public async remove(key: string): Promise<{}> {
    await this.platform.ready();
    return this.storage.remove(key);
  }

  public async clear(): Promise<void> {
    await this.platform.ready();
    return this.storage.clear();
  }
}
