import { BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Book, BookPrice } from './../../interfaces/books';
import { BookProvider } from './../../providers/book/book.service';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { StorageService } from './../../providers/utils/storage.service';

@Injectable()
export class ScannerServiceProvider {

  lastScannedIBSN: string;
  lastScannedBookInfo = new BehaviorSubject<Book>(null);
  lastScannedBookPrice = new BehaviorSubject<BookPrice>(null);

  barcodeMode = false;

  fastScan: boolean;
  donable = true;

  constructor(
    private bookProvider: BookProvider,
    private http: HttpClient,
    private storageService: StorageService
  ) {
    this.setMenuToggles();
  }

  async getRandomTestIBSN() {
    const ISBNS = await this.http.get('assets/data/isbn.json').pipe(map((data) => data['isbn'])).toPromise();
    this.lastScannedIBSN = ISBNS[Math.floor(Math.random() * ISBNS.length)];
    return this.lastScannedIBSN;
  }

  setISBN(isbn: string) {
    this.lastScannedIBSN = isbn;
  }

  async setMenuToggles() {
    this.fastScan = await this.storageService.get('fastScanToggle');
    if (this.fastScan === null) {
      this.fastScan = false;
      await this.storageService.set('fastScanToggle', false);
    }

    this.donable = await this.storageService.get('donableToggle');
    if (this.donable === null) {
      this.donable = true;
      await this.storageService.set('donableToggle', true);
    }
  }

  menuToggled(value: boolean, key: string) {
    if (key === 'fast_scan') {
      this.storageService.set('fastScanToggle', value);
    } else if (key === 'donable') {
      this.storageService.set('donableToggle', value);
    }
  }

  async refreshBookInfo() {
    try {
      // findBookInfoByISBN also triggers the getAfterScanMotivation toast alerts
      const bookInfo = await this.bookProvider.findBookInfoByISBN(this.lastScannedIBSN);
      this.lastScannedBookInfo.next(bookInfo);
      return Promise.resolve(bookInfo);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async refreshBookPrice() {
    try {
      const bookPrice = await this.bookProvider.findBookPriceByISBN(this.lastScannedBookInfo.value);
      this.lastScannedBookPrice.next(bookPrice);
      return Promise.resolve(bookPrice);
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async checkLastScannedBookIsAddable() {
    const book = this.lastScannedBookInfo.value;
    const bookPrice = this.lastScannedBookPrice.value;
    return book.interested === true && bookPrice.interested === true && bookPrice.price > 0;
  }

  async checkLastScannedBookIsDonable() {
    const book = this.lastScannedBookInfo.value;
    const bookPrice = this.lastScannedBookPrice.value;
    return book.reason === '' && bookPrice.price === 0;
  }

  async addLastScannedBookToBookList() {
    return this.bookProvider.addBookToListByISBN(this.lastScannedBookPrice.value, this.lastScannedBookInfo.value);
  }

}
