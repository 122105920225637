import { Injectable} from '@angular/core';
import { GenericLocalSingleStore } from '../../generics/generic-local-single-store';
import { CheckoutListModel } from '../../models/checkout';
import { BookListApi } from '../../providers/book/book-list-api';

@Injectable()
export class CheckoutListStore extends GenericLocalSingleStore<CheckoutListModel> {
  constructor(
    private bookListApi: BookListApi,
  ) {
    super(CheckoutListModel);
    this.load();
  }

  public async resetListToEmpty() {
    return this.set(new CheckoutListModel());
  }

  public async setOpenList(): Promise<any> {
    const openList = await this.bookListApi.getOpenListsUser();

    if (this.get().value.list_id === openList.list_id) {
      return Promise.resolve();
    } else {
      return this.set(new CheckoutListModel({ list_id: openList.list_id }));
    }
  }

  protected async load(): Promise<any> {
    this.set(
      new CheckoutListModel(),
    );
  }

  protected save(): Promise<any> {
    return Promise.resolve();
  }
}
