import { Injectable } from '@angular/core';
import { FirebaseAnalytics } from '@capacitor-community/firebase-analytics';
import { AnalyticsEventTypes, IGtag } from './../../models/constants/analytics-event-types';
import { PlatformService } from './../../providers/utils/platform.service';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AnalyticsEvent } from './../../models/analytics-event';
import { Subject } from 'rxjs';
import { EventsService } from '../utils/events-service';

@Injectable({ providedIn: 'root' })
export class AnalyticsProvider {
  public readonly analyticsEventSubject = new Subject<AnalyticsEvent>();

  constructor(
    private platform: PlatformService,
    private firebaseWeb: AngularFireAnalytics,
    private eventService: EventsService,
  ) {
  }

  public setListeners() {
    this.analyticsEventSubject.subscribe(data => {
      this.log(data.eventName, data.eventData);
    });

    this.eventService.refreshData.subscribe(eventName => {
      if (eventName === AnalyticsEventTypes.Logout) {
        this.log(AnalyticsEventTypes.Logout, { event_category: 'account', event_label: 'logout' });
      }
    });
  }

  private async log(event: string, additionalData: IGtag = { event_category: 'general', event_label: 'unknown'}) {
    console.log('LOG ANALYTICS EVENT: ' + event);
    await this.logFirebaseAppEvent(event, additionalData);
    await this.logFirebaseWebEvent(event, additionalData);
  }

  private async logFirebaseAppEvent(event: string, additionalData: IGtag) {
    if (!await this.platform.IsApp()) {
      return;
    }

    FirebaseAnalytics.logEvent({name: event, params: additionalData}).then(() => {
      console.log('FIREBASE APP EVENT SENT:', event, additionalData);
    }).catch(() => {
      console.log('FIREBASE APP EVENT FAILED TO SEND:', event, additionalData);
    });
  }

  private async logFirebaseWebEvent(event: string, additionalData: IGtag) {
    if (!(await this.platform.isPwa() || await this.platform.isDesktop())) {
      return;
    }

    this.firebaseWeb.logEvent(event, additionalData).then(() => {
      console.log('FIREBASE WEB EVENT SENT:', event, additionalData);
    }).catch(() => {
      console.log('FIREBASE WEB EVENT FAILED TO SEND:', event, additionalData);
    });
  }
}
