import { UserAuthModel } from './../../models/user-auth';
import { BookListModel } from './../../models/book-list';
import { LoginData, SignupData, ProfileData } from './../../interfaces/users';
import { ListSubmitDataRequestBody } from './../../interfaces/data-requests';
import { DropOffLocationsResponse } from './../../interfaces/logistics';
import { Book, BookPrice } from './../../interfaces/books';
import { OpenLists, ListDetails } from './../../interfaces/lists';
import { AppSettings } from './../../app/app.settings';
import { HttpService } from './../utils/http.service';
import { Injectable } from '@angular/core';
import { NewList } from '../../interfaces/lists';
import { PickupTimeslotsResponse } from '../../interfaces/logistics';
import { HttpServiceOptions } from '../utils/http-service-options';
import { AppConfig } from './../../models/constants/app-config';

@Injectable()
export class BoekenbalieApi {

  public placeHolderPostalCode = '%postalcode%';

  private unauthenticated = new HttpServiceOptions({ use_authentication: false  });
  private authenticated = new HttpServiceOptions({ use_authentication: true, access_token: undefined });

  constructor(
    public httpService: HttpService,
    public appSettings: AppSettings,
  ) {
  }

  public setAuthToken(userAuthModel: UserAuthModel) {
    this.authenticated.access_token = userAuthModel && userAuthModel.auth_token ? userAuthModel.auth_token : null;
  }

  public async createNewList(options: HttpServiceOptions = this.authenticated): Promise<NewList> {
    return this.httpService.post<NewList>(this.appSettings.API.Lists.ListNew, null, options, NewList);
  }

  public async getAllLists(options: HttpServiceOptions = this.authenticated): Promise<OpenLists> {
    return this.httpService.get<OpenLists>(this.appSettings.API.Lists.GetAll, options, OpenLists);
  }

  public async getListById(list_id: string, options: HttpServiceOptions = this.authenticated): Promise<BookListModel> {
    const url = this.httpService.replacePlaceHolders(this.appSettings.API.Lists.ListById, { '%list_id%': list_id });
    return this.httpService.get<BookListModel>(url, options, BookListModel);
  }

  public async removeListById(list_id: string, options: HttpServiceOptions = this.authenticated): Promise<ListDetails> {
    const url = this.httpService.replacePlaceHolders(this.appSettings.API.Lists.ListDeleteById, { '%list_id%': list_id });
    return this.httpService.delete<ListDetails>(url, options, ListDetails);
  }

  public async addBookToList(book: Book, list_id: string, options: HttpServiceOptions = this.authenticated): Promise<BookListModel> {
    const url = this.httpService.replacePlaceHolders(this.appSettings.API.Lists.AddBookToList, { '%list_id%': list_id, '%book_id%': book.book_id });
    return this.httpService.post<BookListModel>(url, null, options, BookListModel);
  }

  public async removeBookFromList(bookId: string, list_id: string, options: HttpServiceOptions = this.authenticated): Promise<BookListModel> {
    const url = this.httpService.replacePlaceHolders(this.appSettings.API.Lists.AddBookToList, { '%list_id%': list_id, '%book_id%': bookId });
    return this.httpService.delete<BookListModel>(url, options, BookListModel);
  }

  public async loginGuestUser(options: HttpServiceOptions = this.unauthenticated): Promise<UserAuthModel> {
    return this.httpService.get<UserAuthModel>(this.appSettings.API.Users.New, options, UserAuthModel);
  }

  public async loginUser(loginData: LoginData, options: HttpServiceOptions = this.unauthenticated): Promise<UserAuthModel> {
    return this.httpService.post<UserAuthModel>(this.appSettings.API.Users.Login, loginData, options, UserAuthModel);
  }

  public async registerUser(signupData: SignupData, user_id: string, options: HttpServiceOptions = this.authenticated): Promise<UserAuthModel> {
    const url = this.httpService.replacePlaceHolders(this.appSettings.API.Users.Register, { '%user_id%': user_id });
    return this.httpService.post<UserAuthModel>(url, signupData, options, UserAuthModel);
  }

  public async registerUserPasswordOnly(signupData: SignupData, user_id: string, options: HttpServiceOptions = this.authenticated): Promise<UserAuthModel> {
    const url = this.httpService.replacePlaceHolders(this.appSettings.API.Users.Register, { '%user_id%': user_id });
    return this.httpService.post<UserAuthModel>(url, signupData, options, UserAuthModel);
  }

  public async requestPasswordReset(email, options: HttpServiceOptions = this.unauthenticated): Promise<UserAuthModel> {
    return this.httpService.post<UserAuthModel>(this.appSettings.API.Users.ResetRequest, { userdata: { email } }, options, UserAuthModel);
  }

  public async resetPassword(token: string, userdata: { password: string; password_confirmation: string }, options: HttpServiceOptions = this.unauthenticated): Promise<UserAuthModel> {
    return this.httpService.put<UserAuthModel>(this.appSettings.API.Users.ResetPassword, { token, userdata }, options, UserAuthModel);
  }

  public async updatePassword(user_id: string, userdata: { current_password: string; password: string; password_confirmation: string }, options: HttpServiceOptions = this.authenticated): Promise<UserAuthModel> {
    const url = this.httpService.replacePlaceHolders(this.appSettings.API.Users.UpdatePassword, { '%user_id%': user_id });
    return this.httpService.put<UserAuthModel>(url, { userdata }, options, UserAuthModel);
  }

  public async deleteUser(user_id: string, options: HttpServiceOptions = this.authenticated): Promise<{'user_id': string}> {
    const url = this.httpService.replacePlaceHolders(this.appSettings.API.Users.Delete, { '%user_id%': user_id });
    return this.httpService.delete<{'user_id': string}>(url, options);
  }

  // Profile
  public async profileData(user_id: string, options: HttpServiceOptions = this.authenticated): Promise<{'userdata': ProfileData}> {
    const url = this.httpService.replacePlaceHolders(this.appSettings.API.Users.Profile, { '%user_id%': user_id });
    return this.httpService.get<{'userdata': ProfileData}>(url, options);
  }

  public async profileUpdate(user_id: string, profile: ProfileData, options: HttpServiceOptions = this.authenticated): Promise<{'user_id': string}> {
    const url = this.httpService.replacePlaceHolders(this.appSettings.API.Users.Profile, { '%user_id%': user_id });
    const newProfileData = {userdata: profile};
    return this.httpService.put<{'user_id': string}>(url, newProfileData, options);
  }

  public async profileRevalidateEmail(profile: ProfileData, options: HttpServiceOptions = this.authenticated): Promise<any> {
    const profileData = {userdata: {revalidate: true}};
    const url = this.httpService.replacePlaceHolders(this.appSettings.API.Users.Profile, { '%user_id%': profile.user_id });
    return this.httpService.put(url, profileData, options);
  }

  // Submit a booklist
  // The user should have registered his account and validated
  // his email address first
  // The response includes a reference number which should be
  // included in the physical delivery to Boekenbalie.
  // The booklists total amount should be higher or equal than
  // the 'sale_amount_minimum' setting, see `/service`
  // `list_amount` should be the sum of amounts for all books in the
  // list, without any promotion bonuses.<br/>
  // `list_count` should be the number of books in the list
  public async submitList(list_id: string, submitData: ListSubmitDataRequestBody, options: HttpServiceOptions = this.authenticated): Promise<any> {
    const url = this.httpService.replacePlaceHolders(this.appSettings.API.Lists.SubmitBookList, { '%list_id%': list_id });
    return this.httpService.post<ListSubmitDataRequestBody>(url, submitData, options, ListSubmitDataRequestBody);
  }

  // Find Book by ISBN
  public async findBookByISBN(ISBN: string, options: HttpServiceOptions = this.authenticated): Promise<Book> {
    const url = this.httpService.replacePlaceHolders(this.appSettings.API.Books.Isbn, { '%isbn%': ISBN });
    return this.httpService.get<Book>(url, options, Book);
  }

  public async findPriceForBook(book: Book, options: HttpServiceOptions = this.authenticated): Promise<BookPrice> {
    const url = this.httpService.replacePlaceHolders(this.appSettings.API.Books.PriceByBookId, { '%book_id%': book.book_id });
    return this.httpService.get<BookPrice>(url, options, BookPrice);
  }

  // Find price for a book but retry a few times until price is available through API.
  public async pollPriceForBook(book: Book, options: HttpServiceOptions = this.authenticated, retries: number = 0): Promise<BookPrice> {
    const response: BookPrice = await this.findPriceForBook(book, options);
    if (response && !response.price && response.price !== 0 && retries < AppConfig.api.findPriceRetries) {
      const delay = (time: number) => new Promise<void>((res) => setTimeout(() => res(), time));
      await delay(3500);
      return this.pollPriceForBook(book, options, retries + 1);
    } else {
      if (AppConfig.api.mockPricesApi) {
        response.price = AppConfig.api.mockPriceValue;
        response.weight = AppConfig.api.mockWeightValue;
      }
      return response;
    }
  }

  public async getStreetCity(postalCode: string, number: number, options: HttpServiceOptions = this.authenticated): Promise<{ street: string; city: string }> {
    const url = this.httpService.replacePlaceHolders(this.appSettings.API.Service.Address, { [this.placeHolderPostalCode]: postalCode, '%number%': number });
    return this.httpService.get<{ street: string; city: string }>(url, options);
  }

  public async checkPromocode(promocode: string, options: HttpServiceOptions = this.authenticated): Promise<{ valid: boolean; desciption: string; amount: number}> {
    const url = this.httpService.replacePlaceHolders(this.appSettings.API.Service.CheckPromotionalCode, { '%what%': 'promo', '%code%': promocode });
    return this.httpService.get<{ valid: boolean; desciption: string; amount: number }>(url, options);
  }

  public async dropOffLocations(countrycode: 'NL' | 'BE', postalcode: string, options: HttpServiceOptions = this.authenticated): Promise<DropOffLocationsResponse> {
    const url = this.httpService.replacePlaceHolders(this.appSettings.API.Logistics.DropOffLocations, { '%countrycode%': countrycode, [this.placeHolderPostalCode]: postalcode });
    return this.httpService.get<DropOffLocationsResponse>(url, options, DropOffLocationsResponse);
  }

  public async pickupTimeslots(countrycode: 'NL' | 'BE', postalcode: string, options: HttpServiceOptions = this.authenticated): Promise<PickupTimeslotsResponse> {
    const url = this.httpService.replacePlaceHolders(this.appSettings.API.Logistics.PickupTimeslots, { '%countrycode%': countrycode, [this.placeHolderPostalCode]: postalcode });
    return this.httpService.get<PickupTimeslotsResponse>(url, options, PickupTimeslotsResponse);
  }
}
