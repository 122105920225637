import { Model } from '../generics/model';

export class CheckoutListModel extends Model {
  constructor(fields: any = {}) {
    super();

    for (const f of Object.keys(fields)) {
      this[f] = fields[f];
    }
  }

  agreed_terms = false;
  add_mailinglist = false;
  post_labels = false;
  post_boxes = false;
  box_count = 0;
  donate_percentage = 0;
  shipping_method: 'shop' | 'pickup' = null;
  pickup_date_timeslot: string = null;
  promo_option: string = null;
  promo_code: string = null;
  list_count = 0;
  list_amount = 0;
  list_id: string = null;
  payment: 'iban' | 'donate' = null;

  isListReady(): boolean {
    return this.list_id !== null;
  }
}
