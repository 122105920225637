import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
//// import { RouterModule, Routes } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';

import { CheckoutSubmit } from './shared/checkout-submit.service';
import { CheckoutProvider } from './shared/checkout.service';
import { CheckoutListStore } from './shared/checkout-list-store';
import { CheckoutListEventsProvider } from './shared/checkout-list-events.service';
// // import { RouterModule, Routes } from '@angular/router';

// const routes: Routes = [
//   {
//     path: '',
//     component: MDProgramsParentComponent,
//     canActivate: [ KeycloakAuthenticatedGuard ],
//     canActivateChild: [ KeycloakAuthenticatedGuard ],

//     children: [
//       {
//         path: '',
//         loadChildren: '../md-programs/programs-list/programs-list.module#MDProgramsListModule'
//       },
//       {
//         path: ':programId/programs-overview',
//         loadChildren: '../md-programs/programs-overview/programs-overview.module#ProgramsOverviewModule'
//       }
//     ]
//   }
// ];
// const routes: Routes = [
//   { path: '', component: CheckoutConfirmedPage },
// ];

@NgModule({
  imports: [
    IonicModule,
    CommonModule,
    FormsModule,
    TranslateModule.forChild(),
    // // RouterModule.forChild(routes),
  ],
  declarations: [

  ],
  providers: [
    CheckoutSubmit,
    CheckoutProvider,
    CheckoutListStore,
    CheckoutListEventsProvider,
  ],
})
export class CheckoutModule {}
