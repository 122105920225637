/**
 * A generic model that our Master-Detail pages list, create, and delete.
 *
 * Change "Item" to the noun your app will use. For example, a "Contact," or a
 * "Customer," or a "Animal," or something like that.
 *
 * The Items service manages creating instances of Item, so go ahead and rename
 * that something that fits your app as well.
 */

// eslint-disable-next-line @typescript-eslint/no-unsafe-declaration-merging
export class Item {

  constructor(fields: any) {
    // Quick and dirty extend/assign fields to this model
    for (const f of Object.keys(fields)) {
      this[f] = fields[f];
    }
  }

}

// eslint-disable-next-line no-redeclare, @typescript-eslint/no-unsafe-declaration-merging
export interface Item {
  [prop: string]: any;
}
