import { PlatformService } from './../utils/platform.service';
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { LoadingOptions } from '@ionic/core';

@Injectable()
export class LoadingProvider {

  currentLoader: HTMLIonLoadingElement;

  constructor(
    public loadingCtrl: LoadingController,
    public platformService: PlatformService,
  ) { }

  // Show the loader
  public async createAndPresent(forcePresent: boolean = false, options: LoadingOptions = {}): Promise<HTMLIonLoadingElement> {

    // Add custom options
    options = {
      cssClass: 'loader',
      message: 'Een moment...',
      ...options,
    };

    this.currentLoader = await this.loadingCtrl.create(options);

    if (forcePresent || await this.platformService.IsApp()) {
      await this.currentLoader.present();
    }

    return this.currentLoader;
  }

  public dismiss() {
    if (this.currentLoader) {
      return this.currentLoader.dismiss();
    }
  }

}
