import { AlertMessages } from './../../../models/constants/alert-messages';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BarcodeScanner, ScanOptions, ScanResult, SupportedFormat } from '@capacitor-community/barcode-scanner';
import { AlertsProvider } from './../../../providers/alerts/alerts';
import { ToastProvider } from './../../../providers/toast/toast';
import { Platform } from '@ionic/angular';

@Injectable()
export class BarcodeScannerProvider {

  scanData: {};
  scanActive = false;
  options: ScanOptions;

  constructor(
    public http: HttpClient,
    public platform: Platform,
    private alertsProvider: AlertsProvider,
    public toastProvider: ToastProvider
  ) { }

  async scanBarcode(): Promise<ScanResult> {
    try {
      const allowed = await this.checkPermission();
      if (allowed) {
        this.scanActive = true;
        const scandata = await BarcodeScanner.startScan({ targetedFormats: [SupportedFormat.EAN_13, SupportedFormat.EAN_8]} );
        await BarcodeScanner.stopScan();
        this.scanActive = false;
        return Promise.resolve(scandata);
      }
    } catch (e) {
      return Promise.reject(e);
    }
  }

  async dismissBarcodeScanner() {
    await BarcodeScanner.stopScan();
    this.scanActive = false;
  }

  async checkPermission() {
    return new Promise(async (resolve) => {
      const status = await BarcodeScanner.checkPermission({ force: true });
      if (status.granted) {
        resolve(true);
      } else if (status.denied) {
        const clickedYes = await this.alertsProvider.showAlertConfirm(AlertMessages.noCameraPermission.title, AlertMessages.noCameraPermission.message, AlertMessages.noCameraPermission.button1, AlertMessages.noCameraPermission.button2);
        if (clickedYes) {
          resolve(false);
          BarcodeScanner.openAppSettings();
        }
      } else {
        resolve(false);
      }
    });
  }

}
