import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';

@Injectable()
export class PlatformService {

  constructor(
    public platform: Platform,
  ) {

  }

  async IsApp(): Promise<boolean> {
    await this.platform.ready();
    return this.platform.is('cordova');
  }

  async isAndroidDevice(): Promise<boolean> {
    await this.platform.ready();
    return this.platform.is('android');
  }

  async isIosDevice(): Promise<boolean> {
    await this.platform.ready();
    return this.platform.is('ios');
  }

  async isPwa(): Promise<boolean> {
    await this.platform.ready();
    return this.platform.is('mobileweb');
  }

  async isDesktop() {
    await this.platform.ready();
    return this.platform.is('desktop');
  }

  async isPwaIos(): Promise<boolean> {
    await this.platform.ready();
    return this.platform.is('ios') && this.platform.is('mobileweb');
  }

  async isPwaAndroid(): Promise<boolean> {
    await this.platform.ready();
    return this.platform.is('android') && this.platform.is('mobileweb');
  }

  async isPwaMobile(): Promise<boolean> {
    // eslint-disable-next-line no-return-await
    return (await this.isPwaIos()) || (await this.isPwaAndroid());
  }

}
