import { AppConfig } from './../../models/constants/app-config';
import { BoekenbalieApi } from './../api/boekenbalieApi';
import { Injectable } from '@angular/core';
import { NewList, OpenLists } from './../../interfaces/lists';
import { UserAuthModel } from './../../models/user-auth';
import { HttpServiceOptions } from '../../providers/utils/http-service-options';
import { Book } from './../../interfaces/books';

@Injectable()
export class BookListApi {

  constructor(
    private boekenbalieApi: BoekenbalieApi,
  ) { }

  public async createNewList(user: UserAuthModel = null): Promise<NewList> {
    const list = await this.boekenbalieApi.createNewList(this.serviceOptions(user));
    return list && list.list_id ? list : null;
  }

  public async getAllListsUser(user: UserAuthModel = null): Promise<OpenLists> {
    return this.boekenbalieApi.getAllLists(this.serviceOptions(user));
  }

  public async getAmountOfLists(user: UserAuthModel = null) {
    const userLists = await this.boekenbalieApi.getAllLists(this.serviceOptions(user));
    return userLists.lists.length;
  }

  public async getListById(list_id: string, user: UserAuthModel = null) {
    const list = await this.boekenbalieApi.getListById(list_id, this.serviceOptions(user));
    if (AppConfig.api.mockPricesApi) {
      for (const book of list.list) {
        book.price = AppConfig.api.mockPriceValue;
        book.weight = AppConfig.api.mockWeightValue;
      }
    }
    return list;
  }

  public async getOpenListsUser(user: UserAuthModel = null) {
    const openListsResp = await this.boekenbalieApi.getAllLists(this.serviceOptions(user));
    const openLists = (openListsResp && openListsResp.lists && openListsResp.lists.length > 0) ? openListsResp.lists : null;
    if (openLists) {
      return openLists.find((x) => x.status === 'open');
    } else {
      return null;
    }
  }

  public async checkMergeDuplicates(oldUser: UserAuthModel, newUser: UserAuthModel): Promise<boolean> {
    const openListOldId = await this.getOpenListsUser(oldUser);
    const openListNewId = await this.getOpenListsUser(newUser);

    const openListOldEntity = await this.getListById(openListOldId.list_id, oldUser) as any;
    const openListNewEntity = await this.getListById(openListNewId.list_id, newUser) as any;

    return openListNewEntity.list.some(
      (newBook) => openListOldEntity.list.findIndex(
        (oldBook) => oldBook.isbn === newBook.isbn) !== -1);
  }

  public async mergeBooklists(oldUser: UserAuthModel, newUser: UserAuthModel): Promise<void> {
    let error;

    const openListOldId = await this.getOpenListsUser(oldUser);
    const openListNewId = await this.getOpenListsUser(newUser);

    const openListOldEntity = await this.getListById(openListOldId.list_id, oldUser) as any;
    const openListNewEntity = await this.getListById(openListNewId.list_id, newUser) as any;
    const credentials = HttpServiceOptions.fromUserAuthModel(newUser, oldUser);

    const mergedIsbns: string[] = []; // for combined checks with old list state and already merged books

    for (const book of openListOldEntity.list) {
      if (this.shouldSkipBookMerge(openListNewEntity, book, mergedIsbns)) {
        continue;
      }

      try {
        const bookRes1 = await this.boekenbalieApi.findBookByISBN(book.isbn, credentials);
        if (!bookRes1.interested) {
          throw new Error();
        }
        const bookRes2 = await this.boekenbalieApi.pollPriceForBook(bookRes1, credentials);
        if (!bookRes2.interested) {
          throw new Error();
        }
        await this.boekenbalieApi.addBookToList({ book_id: bookRes2.book_id } as Book, openListNewId.list_id, credentials);
        mergedIsbns.push(book.isbn);
      } catch (e) {
        // catch to make sure we merge as many books as possible.
        if (!error) {
          error = e;
        }

        console.error(e);
      }
    }

    if (error) {
      return Promise.reject(error);
    } else {
      return Promise.resolve();
    }
  }

  private readonly shouldSkipBookMerge = (targetBookList, book: Book, mergedIsbns: string[]) => {
    const targetListCount = targetBookList.list.filter((newBook) => newBook.isbn === book.isbn).length;
    const mergedCount = mergedIsbns.filter((isbn) => isbn === book.isbn).length;

    if (targetListCount > 0) {
      return true;
    }

    if (targetListCount + mergedCount + 1 > AppConfig.bookList.maxDuplicates) {
      return true;
    }

    return false;
  };

  public async transportGuestBookList(guestUser: UserAuthModel, loggedInUser: UserAuthModel): Promise<void> {

    const openListUser = await this.getOpenListsUser(loggedInUser);
    const openListGuest = await this.getOpenListsUser(guestUser);
    const openListGuestEntity = await this.getListById(openListGuest.list_id, guestUser) as any;

    // Clear out existing logged in user's list
    await this.clearBookList(openListUser.list_id, loggedInUser);

    // Move books from guest list to the now empty user list
    const userCredentials = HttpServiceOptions.fromUserAuthModel(loggedInUser, guestUser);
    for (const book of openListGuestEntity.list) {
      const bookRes1 = await this.boekenbalieApi.findBookByISBN(book.isbn, userCredentials);
      if (!bookRes1.interested) {
        throw new Error();
      }
      const bookRes2 = await this.boekenbalieApi.pollPriceForBook(bookRes1, userCredentials);
      if (!bookRes2.interested) {
        throw new Error();
      }
      await this.boekenbalieApi.addBookToList({ book_id: bookRes2.book_id } as Book, openListUser.list_id, userCredentials);
    }
  }

  private async clearBookList(list_id: string, user: UserAuthModel) {
    const listEntity = await this.getListById(list_id, user) as any;
    const credentials = HttpServiceOptions.fromUserAuthModel(user);

    for (const book of listEntity.list) {
      await this.boekenbalieApi.removeBookFromList(book.book_id, list_id, credentials);
    }
  }

  private serviceOptions(user: UserAuthModel = null): HttpServiceOptions | undefined {
    if (user) {
      return new HttpServiceOptions({ use_authentication: true, access_token: user.auth_token });
    } else {
      return undefined;
    }
  }
}
