import { AnalyticsEventCategories, AnalyticsEventActions } from './../../models/constants/analytics-event-types';
import { BoekenbalieApi } from './../../providers/api/boekenbalieApi';
import { CheckoutSubmit } from './checkout-submit.service';
import { CheckoutListModel } from './../../models/checkout';
import { Injectable } from '@angular/core';
import { CheckoutListStore } from './checkout-list-store';
import { BookListStore } from '../../providers/book/book-list-store';
import { AnalyticsEventTypes } from './../../models/constants/analytics-event-types';
import { AnalyticsProvider } from './../../providers/analytics/analytics';

@Injectable()
export class CheckoutProvider {

  promotionConstants = {
    facebook: {
      v: 'facebook', url: 'https://www.facebook.com/sharer/sharer.php?u=https%3A//www.facebook.com/boekenbalie/', appId: null,
    },
    google: {
      v: 'google', url: 'https://search.google.com/local/writereview?placeid=ChIJR073Y480xEcRvKltXJrPm_U', appId: null,
    },
    store_ios: {
      v: 'store_ios', url: 'https://itunes.apple.com/us/app/boekenbalie/id1115737133', appId: 'id1115737133',
    },
    store_android: {
      v: 'store_android', url: 'https://play.google.com/store/apps/details?id=com.boekenbalie.app', appId: 'com.boekenbalie.app',
    },
    code: { v: 'code', url: null, appId: null },
    no: { v: 'no', url: null, appId: null },
  };

  constructor(
    private boekenbalieApi: BoekenbalieApi,
    private checkoutSubmit: CheckoutSubmit,
    private store: CheckoutListStore,
    private bookListStore: BookListStore,
    private analytics: AnalyticsProvider,
  ) {
    this.store.get().subscribe((list: CheckoutListModel) => {
      if (!list && !list.list_id) {
        this.store.setOpenList();
      }
    });
  }

  //// Booklist status check
  getBookList() {
    return this.bookListStore.get();
  }

  /// CHECKOUT
  setBoxCount(value: string) {
    // eslint-disable-next-line radix
    this.store.updateProperties({ box_count: parseInt(value, null) });
  }

  setDonatePercentage(value: number) {
    this.store.updateProperties({ donate_percentage: value });
  }

  setPostBoxes(value: boolean) {
    this.store.updateProperties({ post_boxes: value });
  }

  setPostLabels(value: boolean) {
    this.store.updateProperties({ post_labels: value });
  }

  setAgreedTerms(value: boolean) {
    this.store.updateProperties({ agreed_terms: value });
  }

  getAgreedTerms() {
    return this.store.get().value.agreed_terms;
  }

  setPickupDateTimeSlot(value: string) {
    this.store.updateProperties({ pickup_date_timeslot: value });
  }

  async setPromoCode(value: string): Promise<any> {
    this.store.updateProperties({ promo_code: value });
  }

  alwaysReceivesBonus() {
    return [
      this.promotionConstants.facebook.v,
      this.promotionConstants.google.v,
      this.promotionConstants.store_android.v,
      this.promotionConstants.store_ios.v,
    ].indexOf(this.getPromoOption()) > -1;
  }

  async validatePromoCode(code?: string): Promise<{ valid: boolean; desciption: string; amount: number} | any> {
    if (this.alwaysReceivesBonus()) {
      return Promise.resolve(true);
    } else if (!code) {
      code = this.getPromoCode();
    }

    return this.getPromoCodeDetails(code).then((r) => r.valid);
  }

  getPromoCodeDetails(code: string) {
    return this.boekenbalieApi.checkPromocode(code);
  }

  getPromoCode(): string {
    return this.store.get().value.promo_code;
  }

  setPromoOption(option: string) {
    this.store.updateProperties({ promo_code: null, promo_option: option });
  }

  getPromoOption(): string {
    return this.store.get().value.promo_option;
  }

  setShippingMethod(value: 'shop' | 'pickup') {
    this.store.updateProperties({ shipping_method: value });

    if (value === 'shop') {
      this.store.updateProperties({ pickup_date_timeslot: '' });
    }
  }

  setPayment(value: 'iban' | 'donate') {
    this.store.updateProperties({ payment: value });
  }

  getPayment(): 'iban' | 'donate' {
    return this.store.get().value.payment;
  }

  // The user should have registered his account and validated
  // his email address first
  async submitCheckoutList(): Promise<string> {
    const totalPrice = this.bookListStore.get().value.totalPriceEuro;
    const list = await this.checkoutSubmit.submitBookList(this.store.get().value);

    if (list && list.list_id) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      this.analytics.analyticsEventSubject.next({eventName: AnalyticsEventTypes.SubmitBookList, eventData: { event_category: AnalyticsEventCategories.checkout, event_action: 'Lijst verzonden', event_label: list.list_id, value: totalPrice }});
      // eslint-disable-next-line @typescript-eslint/naming-convention
      this.analytics.analyticsEventSubject.next({eventName: AnalyticsEventTypes.SubmitBookList, eventData: { event_category: AnalyticsEventCategories.checkout, event_action: AnalyticsEventActions.SubmitBookList, event_label: list.list_id, value: totalPrice }});
      // eslint-disable-next-line @typescript-eslint/naming-convention
      this.analytics.analyticsEventSubject.next({eventName: AnalyticsEventTypes.SubmitBookList, eventData: { event_category: AnalyticsEventCategories.checkout, event_action: AnalyticsEventActions.CheckoutSuccess, event_label: list.list_id, value: totalPrice }});
      // eslint-disable-next-line sonarjs/no-duplicate-string
      // default and recommedn Analytics events
      // https://support.google.com/firebase/answer/6317499?hl=en
      this.analytics.analyticsEventSubject.next({eventName: AnalyticsEventTypes.EcommercePurchase, eventData: { value: totalPrice, currency: 'EUR' }});
      // eslint-disable-next-line @typescript-eslint/naming-convention
      this.analytics.analyticsEventSubject.next({eventName: AnalyticsEventTypes.Purchase, eventData: { transaction_id: list.list_id, value: totalPrice, currency: 'EUR' }});
    }

    await this.bookListStore.refreshOpenList();
    // Check readme "Publish events to GA"
    return list.list_id;
  }
}
