export class SubmitError {
  constructor(fields: any) {
    // Quick and dirty extend/assign fields to this model
    for (const f of Object.keys(fields)) {
      this[f] = fields[f];
    }
  }

  public errors: string[] = [];

  private _informCallback: () => void;

  get informCallback(): () => void {
    if (this._informCallback) {
      return this._informCallback;
    } else {
      return () => {};
    }
  }

  set informCallback(v: () => void) {
    this._informCallback = v;
  }

  get hasCallback(): boolean {
    if (this._informCallback) {
      return true;
    }
    return false;
  }

  static fromHttpErrorResponse(e) {
    if (e.error && e.error.status) {
      return new SubmitError({ errors: e.error.status });
    } else {
      return new SubmitError({ errors: ['server_error'] });
    }
  }
}
