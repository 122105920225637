import { BehaviorSubject } from 'rxjs';
import { Model } from './model';

export abstract class GenericLocalSingleStore<T extends Model> {
  protected item: BehaviorSubject<T> = new BehaviorSubject<T>(undefined);
  protected classObject: any;

  constructor(classObject) {
    this.classObject = classObject;
  }

  public get(): BehaviorSubject<T> {
    return this.item;
  }

  public async set(item: T): Promise<any> {
    this.item.next(item);
    await this.save(item);
  }

  public updateProperties(properties: object = {}) {
    this.set(new this.classObject({ ...this.item.value as object, ...properties }));
  }

  protected abstract load(): Promise<any>;
  protected abstract save(item: T): Promise<any>;
}
