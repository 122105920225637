import { Injectable } from '@angular/core';
import { GenericLocalSingleStore } from '../../generics/generic-local-single-store';
import { BookListModel } from '../../models/book-list';
import { BookListApi } from './book-list-api';
import { BoekenbalieApi } from './../../providers/api/boekenbalieApi';
import { Book } from './../../interfaces/books';

@Injectable()
export class BookListStore extends GenericLocalSingleStore<BookListModel> {

  currentListIsChecked = false;
  currentSortField: 'title' | 'authors' | 'price' | 'added' = 'added';

  constructor(
    private bookListApi: BookListApi,
    private boekenbalieApi: BoekenbalieApi,
  ) {
    super(BookListModel);
  }

  public async resetListToEmpty() {
    return this.set(new BookListModel());
  }

  // refresh always from API
  // always maximal one open list per user..
  public async refreshOpenList(): Promise<string> {
    const previousBookList = this.get().value;
    const openList = await this.bookListApi.getOpenListsUser();
    let list_id: string;

    if (openList) {
      list_id = openList.list_id;
    } else {
      const list = await this.bookListApi.createNewList();
      list_id = list.list_id;
    }

    await this.set(new BookListModel(await this.bookListApi.getListById(list_id)));

    this.refreshCheckedState(previousBookList);

    return list_id;
  }

  protected async load(): Promise<any> {
    // don't load here because of init/auth could be missing..
  }

  public async addBook(book: Book) {
    const list_id: string = await this.refreshOpenList();
    await this.boekenbalieApi.addBookToList(book, list_id);
    await this.refreshOpenList();
  }

  public async removeBook(book: Book) {
    const list_id: string = await this.refreshOpenList();
    await this.boekenbalieApi.removeBookFromList(book.book_id, list_id);
    await this.refreshOpenList();
  }

  public async removeList() {
    const list_id: string = await this.refreshOpenList();
    await this.boekenbalieApi.removeListById(list_id);
    await this.refreshOpenList();
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  protected save(_v): Promise<any> {
    return Promise.resolve();
  }

  // Compare old and new booklists and reset currentListIsChecked if something changed
  private refreshCheckedState(previousBooklist: BookListModel) {
    const bookList = this.get().value;
    if (previousBooklist && bookList) {
      const previousBooklistIds = previousBooklist.list.map((book) => book.book_id);
      const bookListIds = bookList.list.map((book) => book.book_id);

      const changedBooks = previousBooklistIds.map((id, index) => {
        if (bookListIds.indexOf(id) < 0) {
          return previousBooklist.list[index];
        }
      }).concat(bookListIds.map((id, index) => {
        if (previousBooklistIds.indexOf(id) < 0) {
          return bookList.list[index];
        }
      })).filter((book) => book !== undefined);

      if (changedBooks.length > 0) {
        this.currentListIsChecked = false;
      }
    }
  }
}
