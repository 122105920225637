<ion-header>
  <ion-toolbar color="green">
    <ion-buttons slot="start">
      <ion-button (click)="closeModal()">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light">

  <zxing-scanner #scanner (scanSuccess)="scanSuccessHandler($event)" [formats]="['EAN_13', 'EAN_8']">
  </zxing-scanner>

</ion-content>
