import { FormsModule } from '@angular/forms';
import { SubHeaderComponent } from './sub-header';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

@NgModule({
  exports: [
    SubHeaderComponent,
  ],
  declarations: [
    SubHeaderComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
  providers: []
})
export class SubHeaderModule {}
