import { BoekenbalieApi } from './../api/boekenbalieApi';
import { DropOffLocationsResponse } from './../../interfaces/logistics';
import { Injectable } from '@angular/core';

@Injectable()
export class LogisticsProvider {

  constructor(
    private boekenbalieApi: BoekenbalieApi,
  ) {

  }

  async getDropOffLocations(countryCode: 'NL' | 'BE', zipCode: string): Promise<DropOffLocationsResponse> {
    return this.boekenbalieApi.dropOffLocations(countryCode, zipCode);
  }

  async getPickupTimeSlots(countryCode: 'NL' | 'BE', zipCode: string): Promise<string[]> {
    const resp = await this.boekenbalieApi.pickupTimeslots(countryCode, zipCode);
    return resp && resp.days ? resp.days : null;
  }

  getCountryCode(country: 'NL' | 'BE'): 'NL'| 'BE' {
    if (country === 'NL') {
      return 'NL';
    } else if (country === 'BE') {
      return 'BE';
    }
  }
}
