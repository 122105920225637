import { RefreshBookList } from './../../models/constants/events';
import { Component, EventEmitter, Output } from '@angular/core';
import { BookListModel } from './../../models/book-list';
import { BookListStore } from '../../providers/book/book-list-store';
import { AnalyticsEventCategories, AnalyticsEventTypes } from './../../models/constants/analytics-event-types';
import { AnalyticsProvider } from './../../providers/analytics/analytics';
import { EventsService } from './../../providers/utils/events-service';

@Component({
  selector: 'sub-header',
  templateUrl: 'sub-header.html',
  styleUrls: ['sub-header.scss'],
})
export class SubHeaderComponent {

  @Output() openList = new EventEmitter();
  bookList: BookListModel;

  constructor(
    public analytics: AnalyticsProvider,
    public eventService: EventsService,
    private bookListStore: BookListStore,
  ) {
    this.bookListStore.get().subscribe((bookList: BookListModel) => {
      this.bookList = bookList;
    });
  }

  refreshBookList() {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    this.analytics.analyticsEventSubject.next({ eventName: AnalyticsEventTypes.OpenBookList, eventData: { event_category: AnalyticsEventCategories.checkout, event_action: 'click_on_subheader', value: 0.01 }});
    this.eventService.publish(RefreshBookList);
  }

  get totalAmount(): number {
    return this.bookList && this.bookList.length;
  }

  get totalPrice(): number {
    return this.bookList && this.bookList.totalPriceEuro;
  }

  get totalWeight(): number {
    return this.bookList && this.bookList.totalWeightKg;
  }
}
