<ion-header no-border *ngIf="!showModalHeader">
  <header></header>
</ion-header>

<ion-header *ngIf="showModalHeader && !isCheckoutModal">
  <ion-toolbar color="green">
    <ion-buttons slot="start">
      <ion-button (click)="closeModal()">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-title>
        Conditievoorwaarden
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-header *ngIf="isCheckoutModal">
  <ion-toolbar color="green">
    <ion-title>
        Voordat je verder gaat..
    </ion-title>
    <ion-buttons slot="end">
      <ion-button (click)="dismissCheckoutModal()" data-cy="dismiss-checkout-modal">
        <ion-icon name="close"></ion-icon>
      </ion-button>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <ion-grid>
    <ion-row class="ion-justify-content-center">
      <ion-col size-lg="10">

        <ion-card>
          <ion-card-content>
            <h2 class="ion-text-center ion-padding" *ngIf="isCheckoutModal">Controleer of je boeken bruikbaar zijn</h2>
            <h2 class="ion-text-center ion-padding" *ngIf="!isCheckoutModal">Welke boeken zijn nog bruikbaar?</h2>
            <p class="grid-padding" *ngIf="!isCheckoutModal">
              Boeken die niet voldoen aan deze voorwaarden kun je niet meesturen. Mocht je iets over het hoofd zien, dan is dat niet erg, eventueel trekken we het bedrag van een beschadigd boek na overleg af van het totaal. Is er in een boek geschreven? Of staat er een naam voorin? Dat vinden we niet erg. Je mag uit privacy overweging een wit rechthoekig stickertje over je naam plakken, niet knippen en geen typex gebruiken! Boeken die niet compleet of beschadigd zijn kopen we niet. Deze boeken zijn onbruikbaar.
            </p>
            <p class="grid-padding" *ngIf="isCheckoutModal">
              Boeken die niet voldoen aan deze voorwaarden, zoals boeken die niet compleet of beschadigd zijn, kun je niet meesturen.
            </p>
            <br />
            <p class="grid-padding">
              Alle boeken worden gecontroleerd door ons kwaliteitscontrole team. Kijk <a style="cursor: pointer;" (click)="openWithInAppBrowser('https://boekenbalie.nl/conditievoorwaarden/')">hier</a> voor alle afwijkingen die we niet aannemen.
            </p>

            <div class="conditions-grid">

              <ng-container *ngIf="isCheckoutModal">
                <ion-row>
                  <ion-col size="12" size-sm="6">
                    <ion-img class="center-image" src="../../assets/img/conditievoorwaarden/modal-1-vochtschade.png"></ion-img>
                    <p>Water of vochtschade</p>
                  </ion-col>
                  <ion-col size="12" size-sm="6">
                    <ion-img class="center-image" src="../../assets/img/conditievoorwaarden/modal-2-beschadigingen.png"></ion-img>
                    <p>Grote beschadigingen</p>
                  </ion-col>
                </ion-row>
                <ion-row>
                  <ion-col size="12" size-sm="6">
                    <ion-img class="center-image" src="../../assets/img/conditievoorwaarden/modal-3-werkboek.png"></ion-img>
                    <p>Werkboeken waarin is geschreven</p>
                  </ion-col>
                  <ion-col size="12" size-sm="6">
                    <ion-img class="center-image" src="../../assets/img/conditievoorwaarden/modal-4-losse-paginas.png"></ion-img>
                    <p>Loszittende, ontbrekende of gescheurde pagina’s</p>
                  </ion-col>
                </ion-row>
              </ng-container>

              <ng-container *ngIf="!isCheckoutModal">
                <ion-row>
                  <ion-col size="12" size-sm="4">
                    <ion-img class="center-image" src="../../assets/img/conditievoorwaarden/1-ontbrekende-cd.png"></ion-img>
                    <p>Ontbreken van de bijbehorende CD of DVD</p>
                  </ion-col>
                  <ion-col size="12" size-sm="4">
                    <ion-img class="center-image" src="../../assets/img/conditievoorwaarden/2-vochtschade.png"></ion-img>
                    <p>Water of vochtschade</p>
                  </ion-col>
                  <ion-col size="12" size-sm="4">
                    <ion-img class="center-image" src="../../assets/img/conditievoorwaarden/3-bieb-exemplaren.png"></ion-img>
                    <p>Oude bibliotheek exemplaren</p>
                  </ion-col>
                </ion-row>

                <ion-row>
                  <ion-col size="12" size-sm="4">
                    <ion-img class="center-image" src="../../assets/img/conditievoorwaarden/4-kinderzwerfkei.jpg"></ion-img>
                    <p>Gratis boeken zoals Kinderzwerfkei</p>
                  </ion-col>
                  <ion-col size="12" size-sm="4">
                    <ion-img class="center-image" src="../../assets/img/conditievoorwaarden/5-reparatie.png"></ion-img>
                    <p>Reparaties</p>
                  </ion-col>
                  <ion-col size="12" size-sm="4">
                    <ion-img class="center-image" src="../../assets/img/conditievoorwaarden/6-versleten.png"></ion-img>
                    <p>Aanzienlijke slijtage</p>
                  </ion-col>
                </ion-row>

                <ion-row>
                  <ion-col size="12" size-sm="4">
                    <ion-img class="center-image" src="../../assets/img/conditievoorwaarden/7-losse-paginas.jpg"></ion-img>
                    <p>Loszittende, ontbrekende of gescheurde pagina’s</p>
                  </ion-col>
                  <ion-col size="12" size-sm="4">
                    <ion-img class="center-image" src="../../assets/img/conditievoorwaarden/8-rookgeur.png"></ion-img>
                    <p>Sterke geur (zoals bijvoorbeeld sigarettengeur)</p>
                  </ion-col>
                  <ion-col size="12" size-sm="4">
                    <ion-img class="center-image" src="../../assets/img/conditievoorwaarden/9-werkboek.png"></ion-img>
                    <p>Werkboeken waarin is geschreven</p>
                  </ion-col>
                </ion-row>

                <ion-row>
                  <ion-col size="12" size-sm="4">
                    <ion-img class="center-image" src="../../assets/img/conditievoorwaarden/10-erg-vies.png"></ion-img>
                    <p>Erg vies</p>
                  </ion-col>
                  <ion-col size="12" size-sm="4">
                    <ion-img class="center-image" src="../../assets/img/conditievoorwaarden/11-beschadigingen.png"></ion-img>
                    <p>Grote beschadigingen</p>
                  </ion-col>
                  <ion-col size="12" size-sm="4">
                    <ion-img class="center-image" src="../../assets/img/conditievoorwaarden/12-stofomslag.png"></ion-img>
                    <p>Ontbreken van de stofomslag</p>
                  </ion-col>
                </ion-row>

                <ion-row>
                  <ion-col size="12" size-sm="4">
                    <ion-img class="center-image" src="../../assets/img/conditievoorwaarden/13-vervormd.png"></ion-img>
                    <p>Sterk vervormd</p>
                  </ion-col>
                  <ion-col size="12" size-sm="4">
                    <ion-img class="center-image" src="../../assets/img/conditievoorwaarden/14-isbn.png"></ion-img>
                    <p>Alle delen, indien het een serie onder een ISBN nummer is</p>
                  </ion-col>
                  <ion-col size="12" size-sm="4">
                    <ion-img class="center-image" src="../../assets/img/conditievoorwaarden/15-toegangscode.png"></ion-img>
                    <p>Toegangscode ontbreekt of gebruikt</p>
                  </ion-col>
                </ion-row>
              </ng-container>
            </div>

            <ion-button expand="block" color="salmon" *ngIf="isCheckoutModal" (click)="dismissCheckoutModal()">Sluiten</ion-button>

          </ion-card-content>
        </ion-card>

      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
