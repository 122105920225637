import { BehaviorSubject } from 'rxjs';
import { BookListStore } from './../book/book-list-store';
import { LoadingProvider } from './../loading/loading';
import { UserAuthModel } from './../../models/user-auth';
import { SignupData, LoginData } from './../../interfaces/users';
import { Injectable } from '@angular/core';
import { StorageService } from '../utils/storage.service';
import { AppSettings } from '../../app/app.settings';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BoekenbalieApi } from './../api/boekenbalieApi';
import { GuestUserSignupEvent, RefreshBookList } from '../../models/constants/events';
import { BookListApi } from '../../providers/book/book-list-api';
import * as Sentry from '@sentry/capacitor';
import { AnalyticsProvider } from '../analytics/analytics';
import { EventsService } from '../utils/events-service';
import { AnalyticsEventTypes } from './../../models/constants/analytics-event-types';

@Injectable()
export class AuthenticationProvider {
  private userAuth: UserAuthModel = new UserAuthModel();
  public userAuth$ = new BehaviorSubject<UserAuthModel>(this.userAuth);

  constructor(
    private appSettings: AppSettings,
    private storageService: StorageService,
    public boekenbalieApi: BoekenbalieApi,
    private analytics: AnalyticsProvider,
    private eventsService: EventsService,
    private bookListApi: BookListApi,
    private loadingProvider: LoadingProvider,
    private bookListStore: BookListStore,
  ) {
    this.eventsService.subjectGeneric.subscribe(data => {
      if (data.eventName === this.appSettings.events.SessionInvalidEvent) {
        this.invalidateSession(data.eventData);
      }
    });
  }

  async startSession() {
    try {
      const userAuthModel = await this.init();
      if (userAuthModel === null) {
        this.loginAsGuest();
      }
    } catch (e) {
      // corrupt session / token invalid?
      this.invalidateSession(e);
    }
  }

  invalidateSession(error?) {
    Sentry.captureException(error.originalError || error);
    if (error.message || error.status || error.statusText) {
      Sentry.captureMessage((error.message || '') + ', ' + (error.status || '') + ', ' + (error.statusText || ''));
    }

    this.deleteUserAuth(false);
    this.userAuth$.next(this.userAuth);
    alert('De sessie is verlopen of ongeldig. Probeer opnieuw in te loggen of open de app opnieuw.');
    this.loginAsGuest();
  }

  private async init(): Promise<UserAuthModel> {
    try {
      const userAuthModel = await this.storageService.get(this.appSettings.storageKeys.user_auth);
      if (userAuthModel && userAuthModel.auth_token) {
        await this.setAndSaveUserAuth(userAuthModel);
        return userAuthModel;
      } else {
        return null;
      }
    } catch (e) {
      console.error(e);
      return e;
    }
  }

  // ........................ set tokens ...................................

  private async setAndSaveUserAuth(userAuthModel: UserAuthModel) {
    /// check if guest user signup or loggedIn
    /// needed for bookList questions; move to (new) account?
    const guestUserSignup = (this.userAuth && this.userAuth.user_id === userAuthModel.user_id) && this.userAuth.role === 'g';
    const guestUserLoggedIn = (this.userAuth && this.userAuth.user_id !== userAuthModel.user_id) && this.userAuth.role === 'g';

    const oldAuth = this.userAuth;
    this.userAuth = new UserAuthModel(userAuthModel);
    this.boekenbalieApi.setAuthToken(this.userAuth);

    if (guestUserSignup) {
      this.analytics.analyticsEventSubject.next({eventName: GuestUserSignupEvent, eventData: { event_category: 'account', event_label: 'signup' }});
    } else if (guestUserLoggedIn) {
      const userHasListWithBooks = this.bookListStore.get().value.length > 0;
      // need to tranfer books to new loggedin in user, ask the user what he wants
      if (userHasListWithBooks === true) {
        await this.transferBooks(oldAuth, this.userAuth);
      }
    }
    await this.storageService.set(this.appSettings.storageKeys.user_auth, this.userAuth);
    const delay = (time: number) => new Promise<void>((res) => setTimeout(() => res(), time));
    await delay(100);
    this.analytics.analyticsEventSubject.next({eventName: AnalyticsEventTypes.Login, eventData: { event_category: 'account', event_label: 'login' }});
    this.userAuth$.next(this.userAuth);
  }

  public async getUserAuth(): Promise<UserAuthModel> {
    if (this.userAuth) {
      return this.userAuth;
    }
    return this.storageService.get(this.appSettings.storageKeys.user_auth);
  }

  public async deleteUserAuth(restartSession: boolean) {
    this.userAuth = null;
    this.userAuth$.next(this.userAuth);
    await this.storageService.remove(this.appSettings.storageKeys.user_auth);
    this.eventsService.publish(AnalyticsEventTypes.Logout);
    if (restartSession) {
      location.reload();
    }
  }

  private async transferBooks(oldAuth: UserAuthModel, newAuth: UserAuthModel) {
    const options = {
      message: 'De boeken worden gekopieerd. Een moment...',
    };
    const loader = await this.loadingProvider.createAndPresent(true, options);
    try {
      await this.bookListApi.mergeBooklists(oldAuth, newAuth);
      this.eventsService.publish(RefreshBookList);
      loader.dismiss();
    } catch (e) {
      alert('Er is iets fout gegaan met het samenvoegen van je boeken');
      Sentry.captureMessage('Er is iets fout gegaan met het samenvoegen van je boeken');
      Sentry.captureException(e);
      loader.dismiss();
    }
  }

  // ........................................................................

  public async loginWithCredentials(loginData: LoginData): Promise<any> {
    return this.boekenbalieApi.loginUser(loginData).then((resp) => this.handleAuthResponse(resp));
  }

  public async loginAsGuest(): Promise<any> {
    return this.boekenbalieApi.loginGuestUser().then((resp) => this.handleAuthResponse(resp));
  }

  private handleAuthResponse(resp) {
    if (resp && resp.auth_token) {
      return this.setAndSaveUserAuth(resp);
    } else {
      throw new Error();
    }
  }

  async registerNewUser(emailPwOnly: boolean, signupData: SignupData): Promise<boolean> {
    const userAuth = await this.getUserAuth();
    let resp: any;

    if (emailPwOnly === true) {
      resp = await this.boekenbalieApi.registerUserPasswordOnly(signupData, userAuth.user_id);
    } else {
      resp = await this.boekenbalieApi.registerUser(signupData, userAuth.user_id);
    }

    return this.setAndSaveUserAuth(resp).then(() => true);
  }

  public async requestPasswordReset(email: string): Promise<any> {
    return this.boekenbalieApi.requestPasswordReset(email);
  }

  public async resetPassword(token: string, data: { password: string; password_confirmation: string }): Promise<any> {
    return this.boekenbalieApi.resetPassword(token, data);
  }

  public async updatePassword(user_id: string, data: { current_password: string; password: string; password_confirmation: string }): Promise<any> {
    return this.boekenbalieApi.updatePassword(user_id, data);
  }

  public async deleteUser(): Promise<any> {
    const userAuthModel = await this.getUserAuth();
    return this.boekenbalieApi.deleteUser(userAuthModel.user_id);
  }

  public async checkTokenValidityAndRefresh(): Promise<boolean> {
    return Promise.resolve(true);
  }

  public async checkAccessTokenIsExpired(): Promise<boolean> {
    const userAuthModel = await this.getUserAuth();
    const accessToken = userAuthModel && userAuthModel.auth_token ? userAuthModel.auth_token : null;
    if (accessToken) {
      return this.jwtTokenIsExpired(accessToken);
    } else {
      return true;
    }
  }

  // private jwtDecodeToken(myRawToken: string): { exp: number, iss: string, user_id: string, role: string } {
  //   const helper = new JwtHelper();
  //   const decodedToken = helper.decodeToken(myRawToken);
  //   return decodedToken
  // }

  private jwtTokenIsExpired(myRawToken: string): boolean {
    const helper = new JwtHelperService();
    return helper.isTokenExpired(myRawToken);
  }
}
