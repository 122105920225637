import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable()
export class ToastProvider {

  private isToastShowing: boolean = false;
  private shownMessages = new Set<string>();

  constructor(
    public toastCtrl: ToastController,
  ) {
  }

  public async showToast(message, duration?, position?) {

    if (this.isToastShowing && this.shownMessages.has(message)) {
      return;
    }

    const options = {
      message: message || null,
      duration: duration || 6000,
      position: position || 'top',
      color: 'dark',
    };

    const toast = await this.toastCtrl.create({
      message: options.message,
      duration: options.duration,
      position: options.position,
      color: options.color,
    });

    this.isToastShowing = true;
    await toast.present();
    this.shownMessages.add(message);

    toast.onDidDismiss().then(() => {
      this.isToastShowing = false;
      this.shownMessages.clear();
    });
  }
}
