import { NgModule } from '@angular/core';
import { DurationPipe } from './duration/duration';
import { SearchPipe } from './search/search';
import { SortPipe } from './sort/sort';
import { IsRequiredFieldPipe } from './is-required-field/is-required-field';

@NgModule({
  declarations: [
    DurationPipe,
    SearchPipe,
    SortPipe,
    IsRequiredFieldPipe,
  ],
  imports: [],
  exports: [
    DurationPipe,
    SearchPipe,
    SortPipe,
    IsRequiredFieldPipe,
  ],
})
export class PipesModule {}
