import { UserAuthModel } from '../../models/user-auth';

export class HttpServiceOptions {
  constructor(fields: any = {}) {
    // Quick and dirty extend/assign fields to this model
    for (const f of Object.keys(fields)) {
      this[f] = fields[f];
    }
  }

  access_token: string = undefined;
  access_token_other?: string = undefined;
  use_authentication = false;

  static fromUserAuthModel(model: UserAuthModel, otherModel?: UserAuthModel): HttpServiceOptions {
    if (model.auth_token) {
      return new HttpServiceOptions({
        access_token: model.auth_token,
        use_authentication: true,
        ...(otherModel ? { access_token_other: otherModel.auth_token } : {})
      });
    } else {
      return new HttpServiceOptions();
    }
  }
}
