import { FormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { ErrorComponent } from './error';
import { CommonModule } from '@angular/common';

@NgModule({
  exports: [
    ErrorComponent,
  ],
  declarations: [
    ErrorComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
  ],
  providers: []
})
export class ErrorModule { }
