import { Injectable } from '@angular/core';
import { Network } from '@awesome-cordova-plugins/network/ngx';
import { Connection } from './connection.enum';
import { BehaviorSubject } from 'rxjs';
import { Platform } from '@ionic/angular';

@Injectable()
export class ConnectivityProvider {
  // Start with undefined, so app always waits on connectivity on start
  protected _connection: BehaviorSubject<Connection> = new BehaviorSubject<Connection>(undefined);

  protected networkType = '';

  constructor(
    private network: Network,
    private platform: Platform,
  ) {
    this.platform.ready().then(() => {
      this.setListeners();
    });
  }

  public readyAndConnected(): Promise<void> {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise((resolve, _reject) => this.platform.ready().then(() => {
      this.connection.subscribe((type) => {
        if (type !== undefined) {
          this.logNetworkType();
          if (type === Connection.Up) {
            resolve();
          }
        }
      });
    }));
  }

  public isConnected(): boolean {
    return this.connection.getValue() === Connection.Up;
  }

  get connection(): BehaviorSubject<Connection> {
    return this._connection;
  }

  setListeners() {
    this.onChange(() => {
      // delay is required because a new connection
      // exists of a disconnect and a connect-event
      setTimeout(() => {
        const conn = this.connectionType();

        if (conn !== this.connection.getValue()) {
          this._connection.next(conn);
        }

        this.logNetworkType();
      }, 200);
    });

    this.platform.ready().then(() => {
      this._connection.next(this.connectionType());
    });
  }

  // Sentry will queue the events when offline, and send them when reconnected
  private logNetworkType() {
    const networkType = this.platform.is('cordova') ? this.network.type : this.isConnected() ? 'online' : 'offline';

    if (this.networkType !== networkType) {
      this.networkType = networkType;
    }
  }

  protected onChange(next?: (value: any) => void): void {
    if (this.platform.is('cordova')) {
      this.network.onChange().subscribe(next);
    } else {
      window.addEventListener('load', () => {
        window.addEventListener('online', next);
        window.addEventListener('offline', next);
      });
    }
  }

  public connectionType(): Connection {
    if (this.platform.is('cordova')) {
      // check if we have an existent network or things are unknown
      switch (this.network.type) {
      case this.network.Connection.UNKNOWN:
        return Connection.Unknown;

      case this.network.Connection.NONE:
        return Connection.Down;

      default:
        return Connection.Up;
      }
    } else if (navigator.onLine) {
      return Connection.Up;
    } else {
      return Connection.Down;
    }
  }
}
