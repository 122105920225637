import { FormsModule } from '@angular/forms';
import { HeaderComponent } from './header';
import { SubHeaderModule } from '../sub-header/sub-header.module';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { CommonModule } from '@angular/common';

@NgModule({
  exports: [
    HeaderComponent,
  ],
  declarations: [
    HeaderComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SubHeaderModule,
  ],
  providers: []
})
export class HeaderModule {}
