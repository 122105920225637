import { BookListToastAlerts } from './booklist-toast-alerts.service';
import { ProfileData } from './../../interfaces/users';
import { ProfileProvider } from './../profile/profile.service';
import { BoekenbalieApi } from './../api/boekenbalieApi';
import { Injectable } from '@angular/core';
import { Book, BookPrice } from '../../interfaces/books';
import { BookListStore } from './book-list-store';
import { AlertMessages } from './../../models/constants/alert-messages';
import { AlertsProvider } from './../../providers/alerts/alerts';

@Injectable()
export class BookProvider {

  constructor(
    private boekenbalieApi: BoekenbalieApi,
    private profileProvider: ProfileProvider,
    private alertsProvider: AlertsProvider,
    private bookListToastAlerts: BookListToastAlerts,
    private bookListStore: BookListStore,
  ) {
    this.profileProvider.profileData$.subscribe((profileData: ProfileData) => {
      if (profileData && profileData.user_id) {
        this.initBookProvider();
      }
    });

  }

  private async initBookProvider() {
    try {
      await this.bookListStore.refreshOpenList();
    } catch (e) {
      this.alertsProvider.showAlertInform(AlertMessages.errorLoadingList.title, AlertMessages.errorLoadingList.message, AlertMessages.errorLoadingList.button1);
    }
  }

  async findBookInfoByISBN(ISBN: string) {
    try {
      const bookResp = await this.boekenbalieApi.findBookByISBN(ISBN);
      const isDonableReason = bookResp.reason === '';
      // when interesed is false then findBookPriceByISBN is not needed
      await this.bookListToastAlerts.getAfterScanMotivation(bookResp.interested, false, true, isDonableReason, bookResp);
      return Promise.resolve(bookResp);
    } catch (e) {
      if (e && e.error && e.error.status[0] === 'isbn_not_found') {
        await this.bookListToastAlerts.getAfterScanMotivation(null, true, false, true);
      }
      return Promise.reject(e);
    }
  }

  async findBookPriceByISBN(book: Book) {
    const bookPriceResponse = await this.boekenbalieApi.pollPriceForBook(book);
    const isDonablePrice = bookPriceResponse.price === 0;
    if (bookPriceResponse.interested === true && bookPriceResponse.price > 0) {
      return Promise.resolve(bookPriceResponse);
    } else {
      await this.bookListToastAlerts.getAfterScanMotivation(bookPriceResponse.interested, false, false, isDonablePrice, book);
      return Promise.resolve(bookPriceResponse);
    }
  }

  // TODO: Split up logic and events and move higher
  async addBookToListByISBN(bookPriceResponse: BookPrice, book: Book): Promise<boolean> {
    try {
      await this.bookListToastAlerts.getBeforeAddToListMotivation(bookPriceResponse.isbn, this.bookListStore.get().value, bookPriceResponse);
      // add book
      await this.bookListStore.addBook(book);
      // after add to list actions
      this.bookListToastAlerts.getAfterAddToListMotivation(this.bookListStore.get().value, bookPriceResponse);
      return Promise.resolve(true);
    } catch (e) {
      // book was not added
      return Promise.resolve(false);
    }
  }
}
