import { AppSettings } from './../../app/app.settings';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HttpServiceOptions } from './http-service-options';
import { EventsService } from './events-service';

@Injectable()
export class HttpService {
  basicHeaders = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  constructor(
    public http: HttpClient,
    private appSettings: AppSettings,
    private eventsService: EventsService,
  ) { }

  public async get<T>(apiId: string, options: HttpServiceOptions, type?: { new(): T }): Promise<T> {
    try {
      const resp = await this.http.get<T>(apiId, { headers: this.getHeaders(options) }).toPromise();
      return this.tryConstructTypeInstance(resp, type);
    } catch (error) {
      this.handleHttpError(error, options);
    }
    return null;
  }

  public async post<T>(apiId: string, data: any, options: HttpServiceOptions, type?: { new(): T }): Promise<T> {
    try {
      const resp = await this.http.post<T>(apiId, data, { headers: this.getHeaders(options) }).toPromise();
      return this.tryConstructTypeInstance(resp, type);
    } catch (error) {
      this.handleHttpError(error, options);
    }
    return null;
  }

  public async put<T>(apiId: string, data: any, options: HttpServiceOptions, type?: { new(): T }): Promise<T> {
    try {
      const resp = await this.http.put<T>(apiId, data, { headers: this.getHeaders(options) }).toPromise();
      return this.tryConstructTypeInstance(resp, type);
    } catch (error) {
      this.handleHttpError(error, options);
    }
    return null;
  }

  public async delete<T>(apiId: string, options: HttpServiceOptions, type?: { new(): T }): Promise<T> {
    try {
      const resp = await this.http.delete<T>(apiId, { headers: this.getHeaders(options) }).toPromise();
      return this.tryConstructTypeInstance(resp, type);
    } catch (error) {
      this.handleHttpError(error, options);
    }
    return null;
  }

  public replacePlaceHolders(str, data) {
    return str.replace(/%[^%]+%/g, function (match) {
      if (match in data) {
        return (data[match]);
      } else {
        return ('');
      }
    });
  }

  public ObjToArr(data) {
    return Object.keys(data).map(function (item) {
      return data[item];
    });
  }

  private getHeaders(options: HttpServiceOptions): HttpHeaders {
    if (options.use_authentication) {
      return new HttpHeaders({
        ...this.basicHeaders,
        ...{ Authorization: 'Bearer ' + options.access_token },
        ...(options.access_token_other ? { 'Authorization-Other': 'Bearer ' + options.access_token_other } : {} )
      });
    } else {
      return new HttpHeaders(this.basicHeaders);
    }
  }

  private tryConstructTypeInstance<T>(plainObject: T, type?: { new(): T }): T {
    if (type) {
      const classObject = new type();
      Object.assign(classObject, plainObject);
      return classObject;
    }
    return plainObject;
  }

  private handleHttpError(error, options: HttpServiceOptions) {
    if (options.use_authentication && error && error.status && error.status === 401) {
      this.eventsService.publishData(this.appSettings.events.SessionInvalidEvent, error);
    }
    throw error;
  }
}
