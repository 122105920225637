import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  public readonly refreshData = new Subject<string>();
  public readonly subjectGeneric = new Subject<any>();

  publish(message: string) {
    this.refreshData.next(message);
  }

  publishData(message: string, data: any) {
    this.subjectGeneric.next({eventName: message, eventData: data});
  }
}
