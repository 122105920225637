import { BookPrice, Book } from './../../interfaces/books';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ToastProvider } from './../../providers/toast/toast';
import { ToastMessages } from './../../models/constants/toast-messages';
import { ScannerServiceProvider } from '../shared/scanner.service';
import { AnalyticsEventTypes } from './../../models/constants/analytics-event-types';
import { AnalyticsProvider } from './../../providers/analytics/analytics';

@Component({
  selector: 'page-scanner-result',
  templateUrl: 'scanner-result.html',
  styleUrls: ['./scanner-result.scss'],
})
export class ScannerResultPage implements OnInit, OnDestroy {

  isbn: string;
  bookPrice: BookPrice;
  bookInfo: Book;

  constructor(
    private modalCtrl: ModalController,
    public scannerService: ScannerServiceProvider,
    private toastProvider: ToastProvider,
    private analytics: AnalyticsProvider,
  ) {
  }

  ngOnInit() {
    document.addEventListener('backbutton', this.backButtonListener, false);

    this.scannerService.lastScannedBookInfo.subscribe((bookInfoData: Book) => {
      if (bookInfoData) {
        this.bookInfo = bookInfoData;
      }
    });

    this.scannerService.lastScannedBookPrice.subscribe((bookPriceData: BookPrice) => {
      if (bookPriceData) {
        this.bookPrice = bookPriceData;
      }
    });
  }

  ngOnDestroy() {
    document.removeEventListener('backbutton', this.backButtonListener, false);
  }

  private readonly backButtonListener = (event) => {
    this.closeModal();
    event.preventDefault();
    event.stopPropagation();
    return false;
  };

  saleBook() {
    this.modalCtrl.dismiss(true);
  }

  continueScanning() {
    this.toastProvider.showToast(ToastMessages.userDeclinedOffer.text, ToastMessages.userDeclinedOffer.duration, ToastMessages.userDeclinedOffer.position);
    this.modalCtrl.dismiss(false);
  }

  onToggle(event, type) {
    this.scannerService.menuToggled(event, type);
    if (event === true) {
      this.analytics.analyticsEventSubject.next({eventName: AnalyticsEventTypes.ToggleFastScan, eventData: { event_category: 'scanner', event_action: 'fast_scan_toggled_on'}});
    } else if (event !== true) {
      this.analytics.analyticsEventSubject.next({eventName: AnalyticsEventTypes.ToggleFastScan, eventData: { event_category: 'scanner', event_action: 'fast_scan_toggled_off'}});
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
