import { RefreshBookList, LoggedOutEvent } from './../../models/constants/events';
import { BookProvider } from './book.service';
import { Injectable } from '@angular/core';
import { BookListStore } from './book-list-store';
import { EventsService } from '../utils/events-service';

@Injectable()
export class BooklistEventsProvider {

  constructor(
    public eventsService: EventsService,
    public bookProvider: BookProvider,
    private bookListStore: BookListStore,
  ) {
  }

  init() {
    this.eventsService.refreshData.subscribe(data => {
      if (data === RefreshBookList) {
        this.bookListStore.refreshOpenList();
      } else if (data === LoggedOutEvent) {
        this.bookListStore.resetListToEmpty();
      }
    });
  }

}
