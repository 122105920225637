import { Component } from '@angular/core';
import { ProfileData } from '../../interfaces/users';
import { ProfileProvider } from '../../providers/profile/profile.service';
import { NavService } from './../../providers/utils/nav.service';
import { Router } from '@angular/router';
import { BookListModel } from './../../models/book-list';
import { BookListStore } from '../../providers/book/book-list-store';
import { PlatformService } from './../../providers/utils/platform.service';

@Component({
  selector: 'header',
  templateUrl: 'header.html',
  styleUrls: ['header.scss'],
})
export class HeaderComponent {

  // eslint-disable-next-line sonarjs/no-duplicate-string
  private subHeaderNavDisabledPages = ['checkout-list', 'checkout-pack', 'checkout-information', 'checkout-control'];
  profile: ProfileData;
  bookList: BookListModel;

  isDesktop: boolean;

  constructor(
    private navService: NavService,
    private platformService: PlatformService,
    private profileProvider: ProfileProvider,
    private router: Router,
    private bookListStore: BookListStore,
  ) {
    this.profileProvider.profileData$.subscribe((profile: ProfileData) => {
      if (profile) {
        this.profile = profile;
      }
    });

    this.bookListStore.get().subscribe((bookList: BookListModel) => {
      this.bookList = bookList;
    });
  }

  async ngOnInit() {
    this.isDesktop = await this.platformService.isDesktop();
  }

  get totalAmount(): number {
    return this.bookList && this.bookList.length;
  }

  openList() {
    if (this.subHeaderNavDisabledPages.findIndex((pageName) => pageName === this.router.url.substr(1)) === -1) {
      this.navService.navigateRoot('checkout-list');
    }
  }

  gotoLogin() {
    if (this.profile.role === 'guest') {
      this.navService.navigateRoot('login');
    } else {
      this.navService.navigateRoot('my-account');
    }
  }

  gotoCheckoutList() {
    this.navService.navigateRoot('checkout-list');
  }

  gotoScannerPage() {
    this.navService.navigateRoot('scanner-start');
  }

}
