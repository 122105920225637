import { OfflinePage } from './../../pages/offline/offline';
import { Injectable } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ConnectivityProvider } from '../../providers/connection/connectivity.provider';
import { Connection } from '../../providers/connection/connection.enum';

@Injectable()
export class OfflineModalProvider {
  public modal: HTMLIonModalElement;

  constructor(
    private modalCtrl: ModalController,
    private connectivity: ConnectivityProvider,
  ) { }

  initialize() {
    // Platform is ready
    this.connectivity.connection.subscribe((_type: Connection) => {
      if (_type !== undefined) { // initial subject value
        this.hideShowModal(this.connectivity.isConnected());
      }
    });
  }

  private async hideShowModal(isConnected: boolean) {
    if (this.modal) {
      this.dismissModal();
    }

    if (!isConnected) {
      this.openModal();
    }
  }

  private dismissModal() {
    this.modal.dismiss();
    this.modal = undefined;
  }

  private async openModal() {
    this.modal = await this.modalCtrl.create({
      component: OfflinePage,
      showBackdrop: false,
      backdropDismiss: false,
    });

    this.modal.onDidDismiss().then(data => {
      console.log(data);
      setTimeout(() => {
        this.hideShowModal(this.connectivity.isConnected());
      }, 250);
    });

    this.modal.present();
  }
}
