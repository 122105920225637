export class IsbnValidator {

  static validate(value, addSuffix) {
    // This validator uses the same logic as https://formvalidation.io/guide/validators/isbn/
    if (value === '') {
      return true;
    }

    if (addSuffix) {
      value += 'X';
    }

    const type = this.getIsbnType(value);

    switch (type) {
    case 'ISBN10':
      return this.validateIsbn10(value);

    case 'ISBN13':
      return this.validateIsbn13(value);

    default:
      return false;
    }
  }

  private static getIsbnType(value) {
    // http://en.wikipedia.org/wiki/International_Standard_Book_Number#Overview
    // Groups are separated by a hyphen or a space
    switch (true) {
    case /^\d{9}[\dX]$/.test(value):
    case (value.length === 13 && /^(\d+)-(\d+)-(\d+)-([\dX])$/.test(value)):
    case (value.length === 13 && /^(\d+)\s(\d+)\s(\d+)\s([\dX])$/.test(value)):
      return 'ISBN10';
    case /^\d{12}[\dX]$/.test(value):
    case (value.length === 17 && /^\d\d\d-(\d+)-(\d+)-(\d+)-([\dX])$/.test(value)):
    case (value.length === 17 && /^\d\d\d\s(\d+)\s(\d+)\s(\d+)\s([\dX])$/.test(value)):
      return 'ISBN13';
    default:
      return false;
    }
  }

  private static validateIsbn10(value) {
    // Replace all special characters except digits and X
    value = value.replace(/[^0-9X]/gi, '');
    const chars  = value.split('');
    const length = chars.length;
    let sum = 0;
    let i;
    let checksum;

    for (i = 0; i < length - 1; i++) {
      sum += parseInt(chars[i], 10) * (10 - i);
    }
    checksum = 11 - (sum % 11);
    if (checksum === 11) {
      checksum = 0;
    } else if (checksum === 10) {
      checksum = 'X';
    }
    return (checksum + '' === chars[length - 1]);
  }

  private static validateIsbn13(value) {
    // Replace all special characters except digits and X
    value = value.replace(/[^0-9X]/gi, '');
    const chars  = value.split('');
    const length = chars.length;
    let sum = 0;
    let i;
    let checksum;

    for (i = 0; i < length - 1; i++) {
      sum += ((i % 2 === 0) ? parseInt(chars[i], 10) : (parseInt(chars[i], 10) * 3));
    }
    checksum = 10 - (sum % 10);
    if (checksum === 10) {
      checksum = '0';
    }
    return (checksum + '' === chars[length - 1]);
  }
}
