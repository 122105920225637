import { Component, Input } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'page-state-conditions',
  templateUrl: 'state-conditions.component.html',
  styleUrls: ['state-conditions.component.scss'],
})
export class StateConditionsPage {

  @Input() showModalHeader?: boolean;
  @Input() isCheckoutModal?: boolean;

  constructor(
    private modalCtrl: ModalController,
  ) {
  }

  public async openWithInAppBrowser(url: string) {
    await Browser.open({url});
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }

  dismissCheckoutModal() {
    this.modalCtrl.dismiss();
  }

}
