export class UserAuthModel {

  constructor(fields: any = {}) {
    // Quick and dirty extend/assign fields to this model
    for (const f of Object.keys(fields)) {
      this[f] = fields[f];
    }
  }

  auth_token: string;
  user_id: string;
  role: 'r' | 'g' | 'u' | null;

  isGuestUser() {
    return this.role === 'g';
  }

  isRegistered() {
    return (this.role === 'r' || this.role === 'u');
  }

  isRegisteredUnvalidated() {
    return this.role === 'u';
  }

  isRegisteredValidated() {
    return this.role === 'r';
  }

  userRole() {
    switch (this.role) {
    case 'r':
      return 'registered';
    case 'u':
      return 'unvalidated';
    case 'g':
      return 'guest';
    default:
      return null;
    }
  }
}
