<ion-header no-border *ngIf="!isModal">
  <header></header>
</ion-header>

<ion-header *ngIf="isModal">
  <ion-toolbar color="green">
    <ion-title>
        Wachtwoord vergeten
    </ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <ion-grid>
    <ion-row class="ion-justify-content-center">
      <ion-col size-lg="10">

        <div class="ion-padding">
          <ion-img class="center-image" width="100" height="100" src="../../assets/img/IconBoekenbalie.png"></ion-img>
        </div>

        <ion-card class="bordered">
          <ion-card-header>
            <h3 translate>page.password_reset.title</h3>
          </ion-card-header>

          <ion-card-content>

            <ng-container *ngIf="!submitted">
              <p class="ion-margin-bottom" translate>page.password_reset.description</p>
              <form [formGroup]="form" (keydown)="requestNewPassword($event)" data-cy="signup-form" *ngIf="!submitted">
                <ion-list>
                  <ion-item>
                    <ion-label position="stacked" [class.required]="form.controls['email'] | isRequiredField" translate>page.password_reset.fields.email</ion-label>
                    <ion-input formControlName="email" type="email" name="email" data-cy="email-input-field"></ion-input>
                  </ion-item>
                  <error [formControl]="form.controls.email" [showIf]="submitted" [showByTouched]="true" ngDefaultControl data-cy="email-error"></error>
                  <div padding-top>
                    <ion-button color="salmon" expand="block" (click)="requestNewPassword()" data-cy="signup-button" translate>page.password_reset.submit</ion-button>
                  </div>
                </ion-list>
              </form>
            </ng-container>

            <ng-container *ngIf="submitted">
              <p class="ion-margin-bottom" translate>page.password_reset.success</p>
            </ng-container>

          </ion-card-content>
        </ion-card>

        <div>
          <ion-button (click)="openLogin()" fill="clear" color="salmon" expand="block">Naar inloggen</ion-button>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
