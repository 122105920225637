
export class UserDataRequestBody {
  name: string;
  street: string;
  number: string;
  postalcode: string;
  city: string;
  country: string;
  phone: string;
  email: string;
  iban: string;
  donate: string;
  old_password: string;
  new_password: string;
  revalidate: boolean;
  newsletter: boolean;
}

export class UserLoginRequestBody {
  user_id?: string;
  email: string;
  password: string;
}

export class ListSubmitResponse {
  list_id: string;
  list_reference: string;
}

export class ListSubmitDataRequestBody {
  userdata: {
    agreed_terms: boolean;
    add_mailinglist: boolean;
    post_labels: boolean;
    post_boxes: boolean;
    box_count: number;
    donate_percentage: number;
    shipping_method: 'shop' | 'pickup'; // shop, pickup
    pickup_date_timeslot?: string; // 2018-06-08 12-14 // '%Y-%m-%d %h-%h'
    promo_code: string;
    list_count: number;
    // cents
    list_amount: number;
    payment: string;
  };
}

export class ListRescheduleDataRequestBody {
  pickup_date_timeslot: string; // 2018-06-08 12-14 // '%Y-%m-%d %h-%h'
}
