import { ENV } from './../../env/env';
export const bonusPromo = '3eurobonus';

export const AppConfig = {
  scanner: {
    prefillISBN: false,
  },
  checkout: {
    allowReviewPromo: ENV.allowReviewPromo,
    enableChecklist: false,
    emailValidationIsRequired: true,
    alwaysResendEmailValidationEmail: true,
    // changed back to 25 boxes. Check BOEK-611
    booksPerBox: 25,
  },
  bookList: {
    maxDuplicates: 2,
    minAmount: 10,
    maxAmount: 400,
  },
  api: {
    findPriceRetries: 5,
    mockPricesApi: false,
    mockPriceValue: 789,
    mockWeightValue: 567,
  },
};
