import { AlertsProvider } from './../../providers/alerts/alerts';
import { AuthenticationProvider } from './../../providers/authentication/authentication';
import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ToastProvider } from '../../providers/toast/toast';
import { ToastMessages } from '../../models/constants/toast-messages';
import { ErrorUtil } from '../../providers/utils/error-util';
import { NavService } from './../../providers/utils/nav.service';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'page-password-reset',
  templateUrl: 'password-reset.html',
  styleUrls: ['password-reset.scss'],
})
export class PasswordResetPage {
  form: FormGroup;
  submitted = false;

  @Input() isModal?: boolean;

  constructor(
    public navService: NavService,
    public toastProvider: ToastProvider,
    public translateService: TranslateService,
    public formBuilder: FormBuilder,
    public authenticationProvider: AuthenticationProvider,
    public alertsProvider: AlertsProvider,
    private errorUtil: ErrorUtil,
    private modalCtrl: ModalController
  ) {
  }

  ngOnInit() {
    if (this.form) {
      return;
    }

    this.form = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email, Validators.maxLength(150)])],
    });
  }

  async requestNewPassword(event?) {
    if (event && event.keyCode !== 13) {
      return;
    }

    if (!this.checkFormIsValid()) {
      this.toastProvider.showToast(ToastMessages.formInvalid.text, ToastMessages.formInvalid.duration, ToastMessages.formInvalid.position);
      return;
    } else {
      try {
        await this.authenticationProvider.requestPasswordReset(this.form.value.email);
        this.submitted = true;
        this.form.reset();
      } catch (e) {
        this.errorUtil.handleSubmitError(e, {
          sentryMessageFallback: `PassReset: ${this.errorUtil.buildErrorMessage(e)}`,
        });
      }
    }
  }

  checkFormIsValid() {
    return this.form.valid;
  }

  openLogin() {
    if (this.isModal) {
      this.modalCtrl.dismiss({loginClicked: true});
    } else {
      this.navService.navigateRoot('login');
    }
  }
}
