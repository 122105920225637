import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'error',
  templateUrl: 'error.html',
  styleUrls: ['error.scss'],
  // eslint-disable-next-line @angular-eslint/no-inputs-metadata-property
  inputs: ['formControl', 'form', 'showByTouched', 'showByDirty', 'showIf', 'hideIf'],
})

export class ErrorComponent {
  @Input('formControl') formControl: FormControl;
  @Input('form') form: FormGroup;

  @Input('showByTouched') showByTouched = true;
  @Input('showByDirty') showByDirty = false;
  @Input('showIf') showIf = false;
  @Input('hideIf') hideIf = false;

  constructor(
    private translate: TranslateService,
  ) { }

  get showing(): boolean {
    const showDirty = (!this.control.pristine && this.showByDirty);
    const showTouched = (this.control.touched && this.showByTouched);

    return this.control && !this.hideIf &&
      (!this.control.valid && this.control.errors !== null) &&
      (this.showIf || showTouched || showDirty);
  }

  get message() {
    if (!this.showing) {
      return null;
    }

    const error = Object.keys(this.control.errors)[0];
    return this.translate.instant(`form.errors.${error}`);
  }

  get control() {
    if (this.form) {
      return this.form;
    } else if (this.formControl) {
      return this.formControl;
    } else {
      throw new Error('Please use [formControl]= or [form]=');
    }
  }
}
