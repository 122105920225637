<ion-header>
  <ion-toolbar>
    <ion-title>Offline</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content color="light">

  <ion-grid>
    <ion-row>
      <ion-col>
          <br /><br /><br />
          <img src="assets/icon/icon-geen-internet.png"/>
          <br />
          <br />
          <h1>Er is geen internetverbinding</h1>
          <h4>Probeer het later opnieuw wanneer je weer online bent</h4>
          <br />
          <ion-button (click)="dismissModal()" color="salmon" expand="block">Sluiten</ion-button>
      </ion-col>
    </ion-row>
  </ion-grid>

</ion-content>
