// General analytics event types (both GA and Firebase)
export const AnalyticsEventTypes = {
  Login: 'login',
  Logout: 'logout',
  DeleteBookList: 'delete_book_list',
  SubmitBookList: 'submit_book_list',
  OpenBookList: 'open_book_list',
  Review: 'review',
  ClickButton: 'click_button',
  PageOpen: 'page_open',
  PageLeave: 'page_leave',
  ToggleFastScan: 'toggle_fast_scan',
  ToggleDonable: 'toggle_donable',
  // Obsolete
  // Google Analytics specific event types
  BeginCheckout: 'begin_checkout',
  CheckoutSubmit: 'checkout_submit',
  CheckoutProgressControl: 'page_open_checkout_control',
  CheckoutProgressPack: 'page_open_checkout_pack',
  CheckoutProgressDonate: 'page_open_checkout_donate',
  CheckoutProgressConfirm: 'page_open_checkout_confirm',
  CheckoutProgressInformation: 'page_open_checkout_information',
  CheckoutSuccess: 'checkout_success',
  // for GTAG only
  Purchase: 'purchase',
  // Obsolete
  // Firebase specific event types
  AddToCart: 'add_to_cart',
  EcommercePurchase: 'ecommerce_purchase',
};

export const AnalyticsEventActions = {
  PageOpen: 'page_open',
  ButtonClick: 'button_click',
  SubmitBookList: 'booklist_submitted',
  CheckoutPackPageOpen: 'open_pack',
  CheckoutSuccess: 'checkout_success',
};

export const AnalyticsEventCategories = {
  checkout: 'checkout',
  scanner: 'scanner',
  account: 'account',
};

// Obsolete
// Google Analytics specific event types
export const GoogleAnalyticsEventTypes = {
  AddToCart: 'add_to_cart',
  BeginCheckout: 'begin_checkout',
  CheckoutProgress: 'checkout_progress',
  Purchase: 'purchase',
};

// Obsolete
// Firebase specific event types
export const FirebaseEventTypes = {
  AddToCart: 'add_to_cart',
  BeginCheckout: 'begin_checkout',
  EcommercePurchase: 'ecommerce_purchase',
};

// eslint-disable-next-line
export interface IGtag {
  event_category: string;
  event_label: string;
  event_action?: string;
  value?: number;
  currency?: string;
  country?: string;
}
