export class NewList {
  list_id: string;
}

export class OpenLists {
  lists: OpenList[];
}

export class OpenList {
  list_id: string;
  status: 'open' | 'submitted' | 'received' | 'processed' | 'paid';
  // weight
  count: number;
  // amount of books
  amount: number;
  list_reference: string;
  pickup_date_timeslot: string;
  submitted_at: string;
  donate_percentage: number;
  total_amount: number;
  promo_amount: number;
  bonus_amount: number;
  total_user_amount: number;
  total_donate_amount: number;
}

export class ListDetails {
  authors: string;
  book_id: string;
  price: number;
  subtitle: string;
  title: string;
  weight: number;
  isbn: string;
}

// lists: [{list_id: '3hvQh4877VU38kWUFn05Bw', status: 'open', count: 10, amount: 1500},{list_id: 'tQ1UtEJy-0VgRgPSJOd0Iw', status: 'submitted', count: 15, amount: 2000, pickup_date_timeslot: '2018-06-08 12-14'}]
