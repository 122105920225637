import { ToastProvider } from './../toast/toast';
import { AlertsProvider } from './../alerts/alerts';
import { AlertMessages } from './../../models/constants/alert-messages';
import { Injectable } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SubmitError } from '../../models/submit-error';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastController } from '@ionic/angular';
import * as Sentry from '@sentry/capacitor';
import { AnalyticsProvider } from '../analytics/analytics';

interface SubmitErrorAlertMessage { title: string; message: string; button1: string; button2?: string }
interface SubmitErrorToastMessage { text: string; duration: number; position: string }
interface SubmitErrorEvent { topic: string; category: string; action: string }
interface SubmitErrorParams {
  alertMessage?: SubmitErrorAlertMessage;
  toastMessage?: SubmitErrorToastMessage;
  event?: SubmitErrorEvent;
  sentryMessageFallback?: string;
}

@Injectable()
export class ErrorUtil {
  constructor(
    private readonly translate: TranslateService,
    private readonly toastCtrl: ToastController,
    private readonly alertsProvider: AlertsProvider,
    private readonly toastProvider: ToastProvider,
    private readonly analytics: AnalyticsProvider,
  ) { }

  public async showToastFromError(e: Error | SubmitError, context: string = null) {
    let submitError = new SubmitError({ errors: ['server_error'] });
    let message = '';
    let throwAgain = false;

    if (e instanceof HttpErrorResponse) {
      submitError = SubmitError.fromHttpErrorResponse(e);
    } else if (e instanceof SubmitError) {
      submitError = e;
    } else {
      throwAgain = true;
    }

    if (context) {
      message += `${context}:\n`;
    }

    message += this.buildErrorMessage(submitError);

    (await this.toastCtrl.create({
      message,
      duration: 6000,
      position: 'bottom',
    })).present();

    if (throwAgain) {
      throw e;
    }
  }

  public hasErrorsOrStatuses(e): boolean {
    return e && ((e.errors && e.errors.length > 0) || (e.error && e.error.status && e.error.status.length > 0));
  }

  public getStatusesFromError(e): string[] {
    return e instanceof SubmitError ? e.errors : [e.error.status];
  }

  public buildErrorMessage(e, joiner = '\n'): string {
    console.error(e);
    const errors: string[] = e instanceof SubmitError ? e.errors :
      e.error && e.error.status && e.error.status instanceof Array ? e.error.status :
        e.error && e.error.status ? [e.error.status] :
          [];

    if (errors.length === 1) {
      return this.translateError(errors[0]);
    }

    return errors.map((errorString) => '• ' + this.translateError(errorString)).join(joiner);
  }

  public generateAlertPropertiesFromErrors(form: FormGroup): any {
    const controls = this.convertToFlatControlList(form, (control: FormControl) => !control.valid);

    return {
      title: 'We helpen je graag',
      message: Object.keys(controls).map((k: string) => {
        const translatedField = this.translate.instant(`form.fields.${k}`);
        return `<strong>${translatedField}:</strong><ul>` + Object.keys(controls[k].errors).map((e) => '<li>' + this.translate.instant(`form.errors.${e}`) + '</li>') + '</ul>';
      }).join(''),
      buttons: [{ text: 'Ok' }],
    };
  }

  public convertToFlatControlList(form: FormGroup, filterFunc: (FormControl) => boolean = () => true): any {
    const values = {};

    Object.keys(form.controls).forEach((key) => {
      const currentControl = form.controls[key];

      if (currentControl instanceof FormGroup) {
        Object.assign(values, this.convertToFlatControlList(currentControl, filterFunc));
      } else if (filterFunc(currentControl)) {
        values[key] = currentControl;
      }
    });

    return values;
  }

  /**
   * Generic handler for submit exceptions.
   * @param e The exception or error, usually of type SubmitError or string. Also supports fallback handling for different error types.
   * @param p The parameters. Undefined values result in the steps being skipped.
   */
  // eslint-disable-next-line sonarjs/cognitive-complexity
  public async handleSubmitError(e, p: SubmitErrorParams) {
    if (!e) {
      return;
    }
    const _e = typeof e === 'string' ?
      new SubmitError({ errors: [e] }) :
      e instanceof HttpErrorResponse ?
        SubmitError.fromHttpErrorResponse(e) :
        e;

    let button1 = undefined;
    let button2 = undefined;

    let message = p.alertMessage ? p.alertMessage.message : undefined;
    if (p.alertMessage) {
      button1 = p.alertMessage.button1 ? p.alertMessage.button1 : undefined;
      button2 = p.alertMessage.button2 ? p.alertMessage.button2 : undefined;
    }

    if (_e instanceof SubmitError) {
      message = this.hasErrorsOrStatuses(_e) ? this.buildErrorMessage(_e) : message;
      this.handleSubmitErrorEvent(_e, p, message);
      if (await this.handleSubmitErrorCallback(_e, p, message, button1, button2)) {
        return;
      }
    } else {
      this.handleSubmitErrorFallback(_e, p);
    }

    if (p.alertMessage) {
      this.alertsProvider.showAlertInform(p.alertMessage.title, message, p.alertMessage.button1);
    } else if (p.toastMessage) {
      this.toastProvider.showToast(p.toastMessage.text, p.toastMessage.duration, p.toastMessage.position);
    } else {
      this.showToastFromError(_e);
    }
  }

  private translateError(error: string): string {
    const translation = this.translate.instant(`errors.${error}`);
    if (error === translation) {
      Sentry.captureMessage(`Error translation failed: ${error}`);
    }
    return translation;
  }

  private handleSubmitErrorEvent(e, p: SubmitErrorParams, message: string) {
    if (p.event) {
      // eslint-disable-next-line @typescript-eslint/naming-convention
      this.analytics.analyticsEventSubject.next({ eventName: p.event.topic, eventData: { event_category: p.event.category, event_action: p.event.action, event_label: message}});
      Sentry.captureMessage(`${p.event.topic} ${p.event.action} with status ${this.getStatusesFromError(e)}`);
    } else if (p.sentryMessageFallback) {
      Sentry.captureMessage(p.sentryMessageFallback);
    }
  }

  private async handleSubmitErrorCallback(e, p: SubmitErrorParams, message: string, button1: string, button2: string): Promise<boolean> {

    if (e.hasCallback && p.alertMessage) {
      const clickedYes = await this.alertsProvider.showAlertConfirm(AlertMessages.actionRequiredDuringSubmit.title, message, button1, button2);
      if (clickedYes) {
        e.informCallback();
      }

      return true;
    }

    return false;
  }

  private handleSubmitErrorFallback(e, p: SubmitErrorParams) {
    if (p.sentryMessageFallback) {
      Sentry.captureMessage(p.sentryMessageFallback);
      Sentry.captureException(e);
    }
  }
}
