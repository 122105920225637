import * as IBAN from 'iban';
import { IsbnValidator } from './../validators/isbn.validator';
import { FormControl, ValidationErrors, AbstractControl } from '@angular/forms';
import { BoekenbalieApi } from '../providers/api/boekenbalieApi';

export class InputValidator {

  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    const config = {
      required: 'Required',
      invalidCreditCard: 'Is invalid credit card number',
      invalidEmailAddress: 'Invalid email address',
      invalidPassword: 'Invalid password. Password must be at least 6 characters long, and contain a number.',
      minlength: `Minimum length ${validatorValue.requiredLength}`,
    };

    return config[validatorName];
  }

  static creditCardValidator(control) {
    if (!control.value || control.pristine) {
      return null;
    }
    // Visa, MasterCard, American Express, Diners Club, Discover, JCB
    if (control.value.match(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)) {
      return null;
    } else {
      return { invalidCreditCard: true };
    }
  }

  static ibanValidator(control) {
    if (!control.value || control.pristine) {
      return null;
    }
    if (IBAN.isValid(control.value)) {
      return null;
    } else {
      return { invalidIban: true };
    }
  }

  static postalCodeBEValidator(control) {
    if (!control.value || control.pristine) {
      return null;
    }
    if (control.value.match(/^[1-9]{1}[0-9]{3}$/i)) {
      return null;
    } else {
      return { invalidPostalCodeBE: true };
    }
  }

  static postalCodeNLValidatior(control) {
    if (!control.value || control.pristine) {
      return null;
    }
    //// ^ matches beginning of string
    // [1-9][0-9]{3} matches a single non-zero digit, and three 0-9 digits
    // ? matches 0 or 1 spaces (you could use * to match 0 or more spaces)
    // (?!sa|sd|ss) is a lookahead test to check that the remainder is not "sa", "sd", or "ss".
    // [a-z]{2} matches 2 a-z characters
    // $ matches the end of the string
    // i at the end is the case-insensitive modifier
    if (control.value.match(/^[1-9][0-9]{3} ?(?!sa|sd|ss)[a-z]{2}$/i)) {
      return null;
    } else {
      return { invalidPostalCode: true };
    }
  }

  static emailValidator(control) {
    if (!control.value || control.pristine) {
      return null;
    }
    // RFC 2822 compliant regex
    if (control.value.match(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
      return null;
    } else {
      return { invalidEmailAddress: true };
    }
  }

  static phoneValidator(control) {
    if (!control.value || control.pristine) {
      return null;
    }
    if (control.value.match(/^((\+|00(\s|\s?\-\s?)?)31(\s|\s?\-\s?)?(\(0\)[\-\s]?)?|0)[1-9]((\s|\s?\-\s?)?[0-9])((\s|\s?-\s?)?[0-9])((\s|\s?-\s?)?[0-9])\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]\s?[0-9]$/)) {
      return null;
    } else {
      return { invalidPhone: true };
    }
  }

  static passwordValidator(control) {
    if (!control.value || control.pristine) {
      return null;
    }
    // {6,100}           - Assert password is between 6 and 100 characters
    // (?=.*[0-9])       - Assert a string has at least one number
    if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{6,100}$/)) {
      return null;
    } else {
      return { invalidPassword: true };
    }
  }

  static nlBePostcodecheck(control: FormControl, country: 'NL' | 'BE') {
    if (!control || !control.parent) {
      return;
    }

    if (country === 'BE') {
      return InputValidator.postalCodeBEValidator(control);
    } else if (country === 'NL') {
      return InputValidator.postalCodeNLValidatior(control);
    } else {
      return null;
    }
  }

  static promoCodeValidator(api: BoekenbalieApi): (control: FormControl) => Promise<ValidationErrors | null> {
    return async (control: FormControl): Promise<ValidationErrors | null> => {
      const r = await api.checkPromocode(control.value);
      if (r.valid) {
        return null;
      } else {
        return { promotionCodeInvalid: true };
      }
    };
  }

  static validateIsbn(control: AbstractControl, addSuffix: boolean) {
    if (!control.value || control.pristine) {
      return null;
    } else if (IsbnValidator.validate(control.value, addSuffix)) {
      return null;
    } else {
      return { isbnInvalid: true };
    }
  }

  static nameValidator(control) {
    if (!control.value || control.pristine) {
      return null;
    } else if (control.value.match('.*\\S.*[a-zA-z0-9 ]')) {
      return null;
    } else {
      return { invalidName: true };
    }
  }
}
