<ion-toolbar color="medium">
  <img class="logo" src="assets/img/LogoBoekenbalie.svg">
  <ion-buttons slot="start">
    <ion-menu-toggle> 
      <ion-button>
        <ion-icon name="menu"></ion-icon>
      </ion-button>
    </ion-menu-toggle>
  </ion-buttons>

  <ion-buttons slot="end">

    <ion-button (click)="gotoLogin()" class="account-button hidden-md" *ngIf="profile">
      <ion-icon *ngIf="profile.role === 'guest'" name="person-add"></ion-icon>
      <ion-icon *ngIf="profile.role === 'unvalidated'" name="person"></ion-icon>
      <ion-icon *ngIf="profile.role === 'registered'" name="person"></ion-icon>
    </ion-button>

    <ion-button (click)="gotoScannerPage()" class="account-button hidden-md" *ngIf="isDesktop">
      <ion-icon name="scan"></ion-icon>
    </ion-button>

    <ion-button (click)="gotoCheckoutList()" class="account-button hidden-md" *ngIf="isDesktop">
      <ion-icon name="file-tray-full"></ion-icon>
      <ion-badge color="salmon" mode="ios" *ngIf="totalAmount !== 0">{{ totalAmount }}</ion-badge>
    </ion-button>

  </ion-buttons>

</ion-toolbar>
<sub-header (openList)="openList()"></sub-header>
