import { BoekenbalieApi } from './../../providers/api/boekenbalieApi';
import { CheckoutListModel } from './../../models/checkout';
import { Injectable } from '@angular/core';
import { ListSubmitDataRequestBody, ListSubmitResponse } from './../../interfaces/data-requests';
import { SubmitError } from './../../models/submit-error';
import { BookListStore } from '../../providers/book/book-list-store';
import { NavService } from './../../providers/utils/nav.service';
import { StorageService } from '../../providers/utils/storage.service';

@Injectable()
export class CheckoutSubmit {

  private readonly errorsRequiringAction = {
    BackToStep1: ['amount_mismatch', 'amount_invalid', 'count_mismatch'],
    BackToStep2: ['box_count_invalid', 'shipping_method_invalid', 'pickup_date_timeslot_invalid'],
    BackToStep3: ['invalid_payment', 'invalid_iban', 'iban_invalid', 'invalid_donate', 'donate_invalid', 'invalid_email', 'invalid_address', 'invalid_type_explanation'],
  };

  constructor(
    private navService: NavService,
    private boekenbalieApi: BoekenbalieApi,
    private bookListStore: BookListStore,
    private storageService: StorageService
  ) { }

  async submitBookList(checkoutData: CheckoutListModel): Promise<ListSubmitResponse> {
    if (checkoutData.shipping_method === null) {
      return Promise.reject(new SubmitError({
        errors: ['shipping_method_invalid'],
        informCallback: () => {
          this.navService.navigateForward('checkout-pack');
        },
      }));
    }

    if (checkoutData.agreed_terms === false) {
      return Promise.reject(new SubmitError({ errors: ['not_agreed'] }));
    }

    // populate data to submit an order
    const finalSubmitList: ListSubmitDataRequestBody = {
      userdata: {
        list_amount: this.bookListStore.get().value.totalPrice,
        list_count: this.bookListStore.get().value.length,
        box_count: checkoutData.box_count || 1,
        donate_percentage: checkoutData.donate_percentage,
        agreed_terms: checkoutData.agreed_terms,
        add_mailinglist: checkoutData.add_mailinglist || false,
        post_labels: checkoutData.post_labels || false,
        post_boxes: checkoutData.post_boxes || false,
        shipping_method: checkoutData.shipping_method || 'shop',
        pickup_date_timeslot: checkoutData.pickup_date_timeslot || '',
        promo_code: checkoutData.promo_code || '',
        payment: checkoutData.payment || '',
      },
    };

    await this.storageService.remove('checkoutModalShown');

    console.log('finalSubmitList:');
    console.log(finalSubmitList);

    try {
      // return await is needed because in try/catch, else catch is not triggered
      return await this.boekenbalieApi.submitList(await this.bookListStore.refreshOpenList(), finalSubmitList);
    } catch (e) {
      this.handleSubmitError(e);
    }
  }

  handleSubmitError(e) {
    if (e && e.status && e.status === 400) {
      throw new SubmitError({
        errors: e.error.status,
        informCallback: () => {
          if (this.errorsRequiringAction.BackToStep1.indexOf(e.error.status[0]) !== -1) {
            this.navService.navigateForward('checkout-list');
          } else if (this.errorsRequiringAction.BackToStep2.indexOf(e.error.status[0]) !== -1) {
            this.navService.navigateForward('checkout-pack');
          } else if (this.errorsRequiringAction.BackToStep3.indexOf(e.error.status[0]) !== -1) {
            this.navService.navigateForward('checkout-information');
          }
        },
      });
    } else {
      throw new SubmitError({ errors: [ 'server_error' ] });
    }
  }
}
