import { Component } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { ScannerServiceProvider } from '../shared/scanner.service';

@Component({
  selector: 'page-scanner-modal',
  templateUrl: 'scanner-modal.html',
  styleUrls: ['./scanner-modal.scss'],
})
export class ScannerModalPage {

  constructor(
    private modalCtrl: ModalController,
    public scannerService: ScannerServiceProvider,
  ) {
  }

  public scanSuccessHandler($event: any) {
    this.modalCtrl.dismiss({result: $event});
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}
