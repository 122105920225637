import { Injectable } from '@angular/core';
import { AppRate } from '@awesome-cordova-plugins/app-rate/ngx';

@Injectable()
export class AppRateProvider {

  constructor(
    public appRate: AppRate,
  ) {
    this.setAppRatePreferences();
  }

  setAppRatePreferences() {
    this.appRate.setPreferences({
      displayAppName: 'Boekenbalie',
      storeAppURL: {
        ios: '1115737133',
        android: 'market://details?id=com.boekenbalie.app'
      },
      customLocale: {
        title: 'Beoordeel %@',
        message: 'Als het gebruik van %@ je bevalt, wil je dan een moment nemen om het te beoordelen? Het duurt nog geen minuut. Bedankt voor je steun!',
        cancelButtonLabel: 'Nee, bedankt',
        laterButtonLabel: 'Herinner me er later aan',
        rateButtonLabel: 'Beoordeel nu',
        yesButtonLabel: 'Ja',
        noButtonLabel: 'Nee',
        appRatePromptTitle: 'Vind je het fijn om de %@ app te gebruiken?',
        feedbackPromptTitle: 'Zou je ons feedback willen geven?'
      },
      simpleMode: true
    });
  }

  showAppRatePrompt() {
    this.appRate.promptForRating(true);
  }

}
