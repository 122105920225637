import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';

@Injectable()
export class AlertsProvider {

  constructor(
    public http: HttpClient,
    public alertCtrl: AlertController,
  ) { }

  public showAlertInform(title, message, btnConfirm): Promise<boolean> {
    const options = {
      title,
      message,
      btnConfirm,
    };

    return new Promise(async (resolve) => {

      const alert = await this.alertCtrl.create({
        // eslint-disable-next-line sonarjs/no-duplicate-string
        cssClass: 'alert-modal',
        header: options.title,
        message: options.message,
        buttons: [
          {
            text: options.btnConfirm,
            // role is required to return to the underlying call when clicking outside of the popup
            role: 'cancel',
            handler: () => {
              resolve(true);
            },
          },
        ],
        backdropDismiss: false,
      });
      alert.present();
    });
  }

  public showAlertConfirm(title, message, btnConfirm, btnCancel): Promise<boolean> {

    const options = {
      title,
      message,
      btnConfirm,
      btnCancel,
    };

    return new Promise(async (resolve) => {

      const alert = await this.alertCtrl.create({
        cssClass: 'alert-modal',
        header: options.title,
        message: options.message,
        buttons: [
          {
            text: options.btnConfirm,
            handler: () => {
              resolve(true);
            },
          },
          {
            text: options.btnCancel,
            role: 'cancel',
            handler: () => {
              resolve(false);
            },
          },
        ],
        backdropDismiss: false,
      });
      alert.present();
    });
  }

  public showAlertQuestion(title, message, btnOne, btnTwo): Promise<number> {

    const options = {
      title,
      message,
      btnOne,
      btnTwo
    };

    return new Promise(async (resolve) => {

      const alert = await this.alertCtrl.create({
        cssClass: 'alert-modal-question',
        header: options.title,
        message: options.message,
        buttons: [
          {
            text: options.btnOne,
            handler: () => {
              resolve(1);
            },
          },
          {
            text: options.btnTwo,
            handler: () => {
              resolve(2);
            },
          },
        ],
        backdropDismiss: false,
      });
      alert.present();
    });
  }
}
