import { Injectable, ErrorHandler } from '@angular/core';
import * as Sentry from '@sentry/capacitor';

@Injectable()
export class AppErrorHandlerProvider implements ErrorHandler {
  constructor() { }

  handleError(err: { originalError?: object }): void {
    Sentry.captureException(err.originalError || err);
    console.error(err.originalError || err);
  }
}
