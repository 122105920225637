export const ToastMessages = {

  /* Checkout price toasts */

  price10to50: {
    text: 'Gefeliciteerd! Je hebt voor meer dan €10,- aan boeken verzameld. De optie om je boeken aan ons te verkopen is nu beschikbaar.',
    duration: 6000,
    position: 'bottom',
  },
  priceFrom400: {
    text: 'Je kunt voor maximaal €400,- per keer boeken aan ons verkopen.',
    duration: 6000,
    position: 'bottom',
  },

  /* Informative toasts */
  formInvalid: {
    text: 'Niet alle velden zijn ingevuld. Zou je het nog even willen controleren?',
    duration: 6000,
    position: 'bottom',
  },
  promoCodeValid: {
    text: 'De code is geldig. Super!',
    duration: 2000,
    position: 'bottom',
  },
  promoCodeInvalid: {
    text: 'Deze code herkennen we niet.',
    duration: 2000,
    position: 'bottom',
  },
  promoCodeNotFilled: {
    text: 'Vul eerst een promocode in.',
    duration: 6000,
    position: 'bottom',
  },
  checkTermsAndConditions: {
    text: 'Je hebt nog geen akkoord gegeven op de algemene voorwaarden.',
    duration: 6000,
    position: 'bottom',
  },
  postalCodeUnknownError: {
    text: 'Er is iets mis gegaan bij het controleren van je postcode. Vul opnieuw je postcode in.',
    duration: 6000,
    position: 'bottom',
  },

  /* Account related toasts */

  profileEdited: {
    text: 'We hebben je profiel aangepast.',
    duration: 6000,
    position: 'bottom',
  },
  profileEditedFormInvalid: {
    text: 'Controleer de opmerkingen.',
    duration: 6000,
    position: 'bottom',
  },
  loginSuccess: {
    text: 'Fijn! Inloggen is gelukt.',
    duration: 6000,
    position: 'bottom',
  },
  loginFailed: {
    text: 'Helaas is het niet gelukt om in te loggen. Probeer het opnieuw.',
    duration: 6000,
    position: 'bottom',
  },
  registrationSuccess: {
    text: 'Registratie is gelukt! Bekijk je e-mail om je account te bevestigen.',
    duration: 6000,
    position: 'bottom',
  },
  registrationFailed: {
    text: 'Registreren is mislukt. Probeer het opnieuw. Als dit niet lukt, neem dan contact met ons op.',
    duration: 6000,
    position: 'bottom',
  },
  registrationEmailInUse: {
    text: 'Dit e-mailadres is al in gebruik. Je kan hiermee inloggen, of gebruik een ander e-mailadres.',
    duration: 6000,
    position: 'bottom',
  },
  passwordResetSuccess: {
    text: 'Reset is gelukt! Bekijk je e-mail om het wachtwoord te activeren.',
    duration: 6000,
    position: 'bottom',
  },

  /* Scanner toasts */
  tooMuchInventory: {
    text: 'We hebben nu teveel voorraad van "%title" van %author, probeer het later nog eens!',
    duration: 6000,
    position: 'bottom',
  },
  donationToggledOff: {
    text: '"%title" van %author kan alleen gedoneerd worden, zet daarvoor de donatie optie aan.',
    duration: 6000,
    position: 'bottom',
  },
  noBid5times: {
    text: 'Boeken zonder bod kun je zelf doneren aan een goed doel bij jou in de buurt.',
    duration: 6000,
    position: 'bottom',
  },
  unknownISBN: {
    text: 'Sorry, dit boek kennen we helaas niet.',
    duration: 6000,
    position: 'bottom',
  },
  unknownISBNTwice: {
    text: 'We herkennen veel boeken, maar helaas niet allemaal.',
    duration: 6000,
    position: 'bottom',
  },
  offerIsZero: {
    text: 'Op dit moment zijn we niet geïnteresseerd in "%title" van %author.',
    duration: 6000,
    position: 'bottom',
  },
  userDeclinedOffer: {
    text: 'Jammer! Dit boek is niet aan je lijst toegevoegd.',
    duration: 6000,
    position: 'bottom',
  },
  invalidISBN: {
    text: 'Je hebt geen of een ongeldig ISBN nummer ingevuld.',
    duration: 6000,
    position: 'bottom',
  },
  helpToasts: [
    'Over het algemeen doen we de hoogste biedingen op recente boeken.', 
    'Lever de boeken schoon aan, zonder prijs stickers en gum zo nodig prijsjes en namen uit.', 
    'Controleer of je boeken loszittende, ontbrekende of gescheurde paginas hebben, die zijn voor ons niet bruikbaar.', 
    'Gebruik stevige dozen voor het verpakken. Bananendozen zijn sterk en ook nog gratis.', 
    'Verzamel je boeken zoveel mogelijk in één order en stuur meerdere orders altijd apart verpakt naar ons op.', 
    'Stuur boeken met water- of schimmelschade niet mee, deze nemen we niet aan.', 
    'Afgeschreven bibliotheekexemplaren kun je niet meesturen.', 
    'We zijn het meest geïnteresseerd in non-fictie. Onze database bevat miljoenen boeken.', 
    'Werkboeken waarin geschreven is zijn helaas niet meer bruikbaar.', 
    'Ons kwaliteitscontroleteam bekijkt alle boeken zorgvuldig. Bekijk even welke boeken nog bruikbaar zijn.'
  ],
  genericError: {
    text: 'Er is iets fout gegaan.',
    duration: 6000,
    position: 'bottom',
  },
};
