export class DropOffLocationsResponse {
  locations: DropoffLocation[];
}

export class DropoffLocation {
  name: string;
  address: string;
  city: string;
  lat: number;
  lng: number;
  open: string;
  closed: string;
}

export class PickupTimeslotsResponse {
  days: string[];
}
