import { AlertsProvider } from './../../providers/alerts/alerts';
import { AuthenticationProvider } from './../../providers/authentication/authentication';
import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormGroup, ValidationErrors, Validators, ValidatorFn, AbstractControl } from '@angular/forms';
import { SignupData } from './../../interfaces/users';
import { ToastProvider } from '../../providers/toast/toast';
import { ToastMessages } from '../../models/constants/toast-messages';
import { ErrorUtil } from '../../providers/utils/error-util';
import { NavService } from './../../providers/utils/nav.service';
import { ModalController } from '@ionic/angular';

export class SignUpFormData {
  email: null;
  new_password: null;
  confirmPassword: null;
  user_type: null;
  type_explanation: null;
  newsletter: null;
}

@Component({
  selector: 'page-signup',
  templateUrl: 'signup.html',
  styleUrls: ['signup.scss'],
})

export class SignupPage {
  account = {
    email: null,
    new_password: null,
    confirmPassword: null,
    user_type: null,
    type_explanation: null,
    newsletter: null
  } as SignUpFormData;

  form: FormGroup;
  signUpData = {
    userdata: {},
  } as SignupData;
  submitted = false;
  submitting = false;

  @Input() isModal?: boolean;

  constructor(
    public navService: NavService,
    public toastProvider: ToastProvider,
    public translateService: TranslateService,
    public formBuilder: FormBuilder,
    public authenticationProvider: AuthenticationProvider,
    public alertsProvider: AlertsProvider,
    private errorUtil: ErrorUtil,
    private modalCtrl: ModalController
  ) {
    this.form = this.formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.email, Validators.maxLength(150)])],
      new_password: ['', Validators.compose([Validators.required, Validators.maxLength(150)])],
      confirmPassword: ['', Validators.compose([Validators.required, Validators.maxLength(150)])],
      type_explanation: ['', Validators.compose([Validators.required, Validators.maxLength(150)])],
      newsletter: [true, Validators.compose([Validators.required, Validators.maxLength(150)])]
    }, { validators: this.validatePasswordConfirmation.bind(this) as ValidatorFn });
  }

  checkFormIsValid() {
    return this.form.valid;
  }

  validatePasswordConfirmation(group: AbstractControl): ValidationErrors | null {
    if (group.get('new_password').value === group.get('confirmPassword').value) {
      return null;
    } else {
      return { password_confirm: true };
    }
  }

  async submit() {
    // make sure page is idle. calling during already active submit will result in session invalidation
    if (this.submitting) {
      return;
    }

    this.submitting = true;

    if (!this.checkFormIsValid()) {
      this.toastProvider.showToast(ToastMessages.formInvalid.text, ToastMessages.formInvalid.duration, ToastMessages.formInvalid.position);
      this.submitting = false;
      return;
    }

    const userType = this.getUserType(this.form.value.type_explanation);

    this.signUpData.userdata = {
      email: this.form.value.email,
      new_password: this.form.value.new_password,
      user_type: userType,
      type_explanation: this.form.value.type_explanation,
      newsletter: this.form.value.newsletter
    };

    try {
      await this.authenticationProvider.registerNewUser(true, this.signUpData);
      this.toastProvider.showToast(ToastMessages.registrationSuccess.text, ToastMessages.registrationSuccess.duration, ToastMessages.registrationSuccess.position);
      if (this.isModal) {
        this.modalCtrl.dismiss();
      }
      this.navService.navigateForward('checkout-list');
    } catch (e) {
      this.errorUtil.handleSubmitError(e, {
        sentryMessageFallback: `Signup: ${this.errorUtil.buildErrorMessage(e)}`,
      });
    }

    this.submitting = false;
  }

  getUserType(type: string) {
    if (type === 'A' || type === 'B' || type === 'C' || type === 'D') {
      return 0;
    } else {
      return 1;
    }
  }

  goToLogin() {
    if (this.isModal) {
      this.modalCtrl.dismiss({loginClicked: true});
    } else {
      this.navService.navigateForward('login');
    }
  }
}
