<ion-toolbar color="green" (click)="refreshBookList()">
  <ion-grid>
    <ion-row>
      <ion-col>
        <div class="subheader-item" (click)="openList.emit()">
          <ion-icon name="book"></ion-icon>
          <span>{{ totalAmount }} boeken</span>
        </div>
      </ion-col>
      <ion-col>
        <div class="subheader-item" (click)="openList.emit()">
          <ion-icon name="cube"></ion-icon>
          <span>{{ totalWeight }} kg</span>
        </div>
      </ion-col>
      <ion-col>
        <div class="subheader-item" (click)="openList.emit()">
          <ion-icon name="cart"></ion-icon>
          <span>{{ totalPrice | currency:'EUR' }}</span>
        </div>
      </ion-col>
    </ion-row>
  </ion-grid>
</ion-toolbar>
