<ion-header>
  <ion-toolbar color="green">
    <ion-buttons slot="start">
      <ion-button (click)="closeModal()">
        <ion-icon name="arrow-back"></ion-icon>
      </ion-button>
    </ion-buttons>
    <ion-buttons slot="end">
      <ion-item lines="none" color="green"> 
        <ion-label>Snel scannen</ion-label>
        <ion-toggle slot="end" color="salmon" [(ngModel)]="scannerService.fastScan" (ngModelChange)="onToggle($event, 'fast_scan')" data-cy="toggle-fastscan"></ion-toggle>
      </ion-item>
    </ion-buttons>
  </ion-toolbar>
</ion-header>

<ion-content color="light">
  <ion-grid>
    <ion-row class="ion-justify-content-center">
      <ion-col size-lg="10">

        <ion-card *ngIf="bookPrice">
          <ion-card-content>
            <div class="book-title">
              <h2>{{bookInfo.title}}</h2>
              <h3>{{bookInfo.authors}}</h3>
            </div>

            <hr />

            <div class="book-offer">
              <p>Ons bod:</p>
              <h3>{{bookPrice.price / 100 | currency: 'EUR'}}</h3>
            </div>

            <ion-button (click)="saleBook()" color="salmon" data-cy="sell-book-submit" expand="block">Dit boek verkopen</ion-button>
            <ion-row class="ion-justify-content-center ion-padding">Of</ion-row>
            <ion-button (click)="continueScanning()" class="secondary-invert" fill="clear" expand="block">Annuleren</ion-button>

          </ion-card-content>
        </ion-card>

        <!-- Als resultaat geen waarde heeft -->
        <ion-card *ngIf="bookInfo && !bookPrice">
          <ion-card-content>
            <div class="book-title">
              <h2>{{bookInfo.title}}</h2>
              <h3>{{bookInfo.authors}}</h3>
            </div>

            <hr />

            <div class="book-offer">
              <p>Er is helaas geen bod.</p>
            </div>

            <hr />

            <ion-button fill="outline" color="salmon" expand="block">Lijst bekijken</ion-button>
            <ion-row class="ion-justify-content-center ion-padding">Of</ion-row>
            <ion-button (click)="continueScanning()" fill="outline" color="salmon" expand="block">Annuleren</ion-button>

          </ion-card-content>
        </ion-card>


      </ion-col>
    </ion-row>
  </ion-grid>
</ion-content>
